import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSession } from '../context/SessionContext'; // Adjust the path as needed
import { useSpin } from '../context/SpinContext';
import './Home.css';

const Home = () => {
  const { session, endSession } = useSession();
  const { resetSpins } = useSpin();
  const navigate = useNavigate();

  const handleSessionClick = async () => {
    if (session) {
      try {
        await endSession(); // This will now properly save pattern history
        resetSpins();
        navigate('/');
      } catch (error) {
        console.error("Error ending session:", error);
      }
    } else {
      navigate('/settings');
    }
  };

  return (
    <div className="home-container">
      <div className="app-title-container">
        <h1>Spin Tracker 3</h1>
        <span className="version-number">v21.03.25a</span>
      </div>
      
      {/* Display session name if a session exists */}
      {session && (
        <div className="session-info">
          <h2 className="session-name">Current Session: {session.name}</h2>
          {session.location && <p className="session-location">{session.location}</p>}
        </div>
      )}
      
      <div className="button-container">
        <button 
          onClick={handleSessionClick} 
          className={`home-button ${session ? 'red' : 'green'}`}
        >
          {session ? 'End Session' : 'Start New Session'}
        </button>
        <Link to="/previous-sessions" className="home-button">View Previous Sessions</Link>
      </div>
    </div>
  );
};

export default Home;