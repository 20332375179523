import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useSession } from "../context/SessionContext";
import { useSpin } from "../context/SpinContext";
import { patternUtils } from '../utils/patternUtils';
import PatternMatchModal from '../components/PatternMatchModal';

import "./Spin.css";

const backendUrl = process.env.REACT_APP_BACKEND_URL;
const BET_OPTIONS_COUNT = 59;

const Spin = () => {
  const [customBetAmount, setCustomBetAmount] = useState(null);
  const [currentStrategy, setCurrentStrategy] = useState("");
  const { session } = useSession();
  const { fetchSpins } = useSpin();
  const navigate = useNavigate();
  const [patternMatches, setPatternMatches] = useState(null);
  const [spinData, setSpinData] = useState({
    session_id: "",
    spin_value: "",
    rbOutcome: "---",
    oeOutcome: "---",
    hlOutcome: "---",
    dozOutcome: "---",
    colOutcome: "---",
    repOutcome: "---",
    neighborsOutcome: "---",
    rbBet: 0,
    oeBet: 0,
    hlBet: 0,
    dozBet: 0,
    colBet: 0,
    repBet: 0,
    neighborsBet: 0
  });

  const [isStrategySelected, setIsStrategySelected] = useState(false);
  const [summaryVisible, setSummaryVisible] = useState(false);

  useEffect(() => {
    if (patternMatches) {
      console.log('patternMatches changed, preventing navigation:', patternMatches);
    }
  }, [patternMatches]);


  useEffect(() => {
    if (session) {
      setSpinData((prevData) => ({ ...prevData, session_id: session._id }));
    }
  }, [session]);

  const handleStrategyChange = (strategy) => {
    setCurrentStrategy(strategy);
    setIsStrategySelected(true);

    // Automatically set loss for zeros in standard strategies
    if ((spinData.spin_value === "0" || spinData.spin_value === "00") &&
      strategy !== "rep" &&
      strategy !== "neighbors") {
      setSpinData(prevData => ({
        ...prevData,
        [`${strategy}Outcome`]: "loss"
      }));
    }
  };

  const handleSpinValueChange = (value) => {
    setSpinData((prevData) => ({ ...prevData, spin_value: value }));
    setCurrentStrategy("");
    setIsStrategySelected(false);
  };

  const handleOutcomeChange = (outcome) => {
    setSpinData((prevData) => {
      const currentOutcome = prevData[`${currentStrategy}Outcome`];
      return {
        ...prevData,
        [`${currentStrategy}Outcome`]:
          currentOutcome === outcome ? "---" : outcome,
        [`${currentStrategy}Bet`]:
          currentOutcome === outcome ? 0 : prevData[`${currentStrategy}Bet`],
      };
    });
  };

  const handleBetAmount = (bet) => {
    if (bet === "custom") {
      return; // Custom bet amount is handled separately
    }

    setSpinData((prevData) => {
      const currentBet = prevData[`${currentStrategy}Bet`];
      return {
        ...prevData,
        [`${currentStrategy}Bet`]: currentBet === bet ? 0 : bet,
      };
    });
    setSummaryVisible(true);
  };

  // const handleNoBet = async () => {
  //   setSpinData((prevData) => ({
  //     ...prevData,
  //     rbOutcome: "---",
  //     oeOutcome: "---",
  //     hlOutcome: "---",
  //     dozOutcome: "---",
  //     colOutcome: "---",
  //     repOutcome: "---",
  //     neighborsOutcome: "---",
  //     rbBet: 0,
  //     oeBet: 0,
  //     hlBet: 0,
  //     dozBet: 0,
  //     colBet: 0,
  //     repBet: 0,
  //     neighborsBet: 0,
  //   }));
  //   setSummaryVisible(false);

  //   // Save immediately when No Bet is selected
  //   try {
  //     const response = await fetch(`${backendUrl}/api/spins`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         ...spinData,
  //         rbOutcome: "---",
  //         oeOutcome: "---",
  //         hlOutcome: "---",
  //         dozOutcome: "---",
  //         colOutcome: "---",
  //         repOutcome: "---",
  //         neighborsOutcome: "---",
  //         rbBet: 0,
  //         oeBet: 0,
  //         hlBet: 0,
  //         dozBet: 0,
  //         colBet: 0,
  //         repBet: 0,
  //         neighborsBet: 0,
  //       }),
  //     });

  //     if (!response.ok) {
  //       throw new Error("Failed to save spin");
  //     }

  //     const savedSpin = await response.json();
  //     console.log("Saved data:", savedSpin);
  //     // await fetchSpins(spinData.session_id); // Fetch spins after adding a new one
  //     // resetForm();
  //     // navigateToDefaultScreen();

  //     // With this updated code:
  //     await fetchSpins(spinData.session_id);

  //     // Check for patterns after adding a new spin
  //     // Replace the pattern detection code in Spin.js handleSave method with this:

  //     // Get all spins for pattern detection
  //     const spinsResponse = await fetch(`${backendUrl}/api/spins/${session._id}/spins`);
  //     const allSpins = await spinsResponse.json();
  //     console.log('Current spins:', allSpins);

  //     // Get pattern detection settings
  //     const patternSettings = {
  //       enableEvenOdds: localStorage.getItem('enableEvenOddsPatterns') !== 'false', // Default to true
  //       enableThirds: localStorage.getItem('enableThirdsPatterns') !== 'false', // Default to true
  //       evenOddsStreakCount: parseInt(localStorage.getItem('evenOddsStreakCount')) || 5,
  //       thirdsStreakCount: parseInt(localStorage.getItem('thirdsStreakCount')) || 5
  //     };

  //     console.log('Using pattern settings:', patternSettings);

  //     // Only run pattern detection if at least one type is enabled
  //     if (patternSettings.enableEvenOdds || patternSettings.enableThirds) {
  //       const patterns = patternUtils.detectPatterns(allSpins, patternSettings);
  //       console.log('Detected patterns:', patterns);

  //       if (patterns) {
  //         // Don't reset form or navigate yet - just show the modal
  //         setPatternMatches(patterns);
  //         console.log('Set pattern matches, waiting for modal interaction');
  //       } else {
  //         // No patterns found, safe to reset and navigate
  //         resetForm();
  //         navigateToDefaultScreen();
  //       }
  //     } else {
  //       // Pattern detection disabled, just reset and navigate
  //       resetForm();
  //       navigateToDefaultScreen();
  //     }

  //   } catch (error) {
  //     console.error("Error saving spin:", error);
  //   }
  // };


  const handleNoBet = async () => {
    setSpinData((prevData) => ({
      ...prevData,
      rbOutcome: "---",
      oeOutcome: "---",
      hlOutcome: "---",
      dozOutcome: "---",
      colOutcome: "---",
      repOutcome: "---",
      neighborsOutcome: "---",
      rbBet: 0,
      oeBet: 0,
      hlBet: 0,
      dozBet: 0,
      colBet: 0,
      repBet: 0,
      neighborsBet: 0,
    }));
    setSummaryVisible(false);
  
    // Save immediately when No Bet is selected
    try {
      const response = await fetch(`${backendUrl}/api/spins`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...spinData,
          rbOutcome: "---",
          oeOutcome: "---",
          hlOutcome: "---",
          dozOutcome: "---",
          colOutcome: "---",
          repOutcome: "---",
          neighborsOutcome: "---",
          rbBet: 0,
          oeBet: 0,
          hlBet: 0,
          dozBet: 0,
          colBet: 0,
          repBet: 0,
          neighborsBet: 0,
        }),
      });
  
      if (!response.ok) {
        throw new Error("Failed to save spin");
      }
  
      const savedSpin = await response.json();
      console.log("Saved data:", savedSpin);
      await fetchSpins(spinData.session_id);
  
      // Get all spins for pattern detection
      const spinsResponse = await fetch(`${backendUrl}/api/spins/${session._id}/spins`);
      const allSpins = await spinsResponse.json();
      console.log('Current spins:', allSpins);
  
      // Get pattern detection settings
      const patternSettings = {
        enableEvenOdds: localStorage.getItem('enableEvenOddsPatterns') !== 'false', // Default to true
        enableThirds: localStorage.getItem('enableThirdsPatterns') !== 'false', // Default to true
        evenOddsStreakCount: parseInt(localStorage.getItem('evenOddsStreakCount')) || 5,
        thirdsStreakCount: parseInt(localStorage.getItem('thirdsStreakCount')) || 5
      };
  
      console.log('Using pattern settings:', patternSettings);
  
      // Only run pattern detection if at least one type is enabled
      if (patternSettings.enableEvenOdds || patternSettings.enableThirds) {
        const patterns = patternUtils.detectPatterns(allSpins, patternSettings);
        console.log('Detected patterns:', patterns);
  
        if (patterns) {
          // Add timestamp and spin number to each pattern
          const patternsWithMeta = Object.entries(patterns).reduce((acc, [key, pattern]) => {
            acc[key] = {
              ...pattern,
              timestamp: Date.now(),
              matchedAtSpin: allSpins.length  // Current spin number
            };
            return acc;
          }, {});
          console.log('Patterns with metadata:', patternsWithMeta);
  
          // Save to pattern history
          const existingPatterns = JSON.parse(localStorage.getItem('patternHistory') || '[]');
          const newPatterns = Object.values(patternsWithMeta);
  
          // Combine existing and new patterns, removing duplicates
          const updatedPatterns = [...existingPatterns];
          newPatterns.forEach(newPattern => {
            const isDuplicate = existingPatterns.some(existing =>
              existing.pattern === newPattern.pattern &&
              existing.patternType === newPattern.patternType
            );
            if (!isDuplicate) {
              updatedPatterns.push(newPattern);
            }
          });
  
          localStorage.setItem('patternHistory', JSON.stringify(updatedPatterns));
          localStorage.setItem('pendingPatterns', JSON.stringify(patternsWithMeta));
          
          // Trigger custom event to notify pattern history was updated
          window.dispatchEvent(new Event('patternHistoryUpdated'));
  
          // Show the pattern matches
          setPatternMatches(patternsWithMeta);
          console.log('Set pattern matches, waiting for modal interaction');
        } else {
          // No patterns found, safe to reset and navigate
          resetForm();
          navigateToDefaultScreen();
        }
      } else {
        // Pattern detection disabled, just reset and navigate
        resetForm();
        navigateToDefaultScreen();
      }
    } catch (error) {
      console.error("Error saving spin:", error);
    }
  };

  const handleSave = async () => {
    if (!spinData.spin_value) {
      alert("Please select a spin value");
      return;
    }
  
    try {
      // Save the spin
      const response = await fetch(`${backendUrl}/api/spins`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(spinData),
      });
  
      if (!response.ok) {
        throw new Error("Failed to save spin");
      }
  
      const savedSpin = await response.json();
      console.log("Spin saved successfully:", savedSpin);
  
      await fetchSpins(spinData.session_id);
  
      // Get all spins for pattern detection
      console.log("Fetching all spins for pattern detection...");
      const spinsResponse = await fetch(`${backendUrl}/api/spins/${session._id}/spins`);
      const allSpins = await spinsResponse.json();
      console.log('Current spins:', allSpins);
  
      const patternSettings = {
        enableEvenOdds: localStorage.getItem('enableEvenOddsPatterns') !== 'false', // Default to true
        enableThirds: localStorage.getItem('enableThirdsPatterns') !== 'false', // Default to true
        evenOddsStreakCount: parseInt(localStorage.getItem('evenOddsStreakCount')) || 5,
        thirdsStreakCount: parseInt(localStorage.getItem('thirdsStreakCount')) || 5
      };
      console.log("Pattern detection settings:", patternSettings);
  
      // Only run pattern detection if at least one type is enabled
      if (patternSettings.enableEvenOdds || patternSettings.enableThirds) {
        console.log("Running pattern detection...");
        const patterns = patternUtils.detectPatterns(allSpins, patternSettings);
        console.log('Detected patterns:', patterns);
  
        if (patterns) {
          // Add timestamp and spin number to each pattern
          const patternsWithMeta = Object.entries(patterns).reduce((acc, [key, pattern]) => {
            acc[key] = {
              ...pattern,
              timestamp: Date.now(),
              matchedAtSpin: allSpins.length  // Current spin number
            };
            return acc;
          }, {});
          console.log('Patterns with metadata:', patternsWithMeta);
  
          // Save to pattern history
          let existingPatterns = [];
          try {
            existingPatterns = JSON.parse(localStorage.getItem('patternHistory') || '[]');
            console.log("Retrieved existing patterns:", existingPatterns);
          } catch (e) {
            console.error("Error parsing existing patterns:", e);
            existingPatterns = [];
          }
          
          const newPatterns = Object.values(patternsWithMeta);
  
          // Combine existing and new patterns, removing duplicates
          const updatedPatterns = [...existingPatterns];
          newPatterns.forEach(newPattern => {
            const isDuplicate = existingPatterns.some(existing =>
              existing.pattern === newPattern.pattern &&
              existing.patternType === newPattern.patternType
            );
            if (!isDuplicate) {
              updatedPatterns.push(newPattern);
            }
          });
  
          console.log("Updating pattern history with:", updatedPatterns);
          localStorage.setItem('patternHistory', JSON.stringify(updatedPatterns));
  
          // Trigger custom event to notify pattern history was updated
          window.dispatchEvent(new Event('patternHistoryUpdated'));
  
          // Save pending patterns for modal and show modal
          localStorage.setItem('pendingPatterns', JSON.stringify(patternsWithMeta));
          setPatternMatches(patternsWithMeta);
          console.log('Set pattern matches, waiting for modal interaction');
        } else {
          // No patterns found, safe to reset and navigate
          resetForm();
          navigateToDefaultScreen();
        }
      } else {
        // Pattern detection disabled, just reset and navigate
        resetForm();
        navigateToDefaultScreen();
      }
    } catch (error) {
      console.error("Error saving spin:", error);
      alert("Error saving spin. Please try again.");
    }
  };
  // const handleSave = async () => {
  //   if (!spinData.spin_value) {
  //     alert("Please select a spin value");
  //     return;
  //   }
  
  //   try {
  //     // Save the spin
  //     const response = await fetch(`${backendUrl}/api/spins`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(spinData),
  //     });
  
  //     if (!response.ok) {
  //       throw new Error("Failed to save spin");
  //     }
  
  //     const savedSpin = await response.json();
  //     console.log("Spin saved successfully:", savedSpin);
  
  //     await fetchSpins(spinData.session_id);
  
  //     // Get all spins for pattern detection
  //     console.log("Fetching all spins for pattern detection...");
  //     const spinsResponse = await fetch(`${backendUrl}/api/spins/${session._id}/spins`);
  //     const allSpins = await spinsResponse.json();
  //     console.log('Current spins:', allSpins);
  
  //     const patternSettings = {
  //       enableEvenOdds: localStorage.getItem('enableEvenOddsPatterns') !== 'false', // Default to true
  //       enableThirds: localStorage.getItem('enableThirdsPatterns') !== 'false', // Default to true
  //       evenOddsStreakCount: parseInt(localStorage.getItem('evenOddsStreakCount')) || 5,
  //       thirdsStreakCount: parseInt(localStorage.getItem('thirdsStreakCount')) || 5
  //     };
  //     console.log("Pattern detection settings:", patternSettings);
  
  //     // Only run pattern detection if at least one type is enabled
  //     if (patternSettings.enableEvenOdds || patternSettings.enableThirds) {
  //       console.log("Running pattern detection...");
  //       const patterns = patternUtils.detectPatterns(allSpins, patternSettings);
  //       console.log('Detected patterns:', patterns);
  
  //       if (patterns) {
  //         // Add timestamp and spin number to each pattern
  //         const patternsWithMeta = Object.entries(patterns).reduce((acc, [key, pattern]) => {
  //           acc[key] = {
  //             ...pattern,
  //             timestamp: Date.now(),
  //             matchedAtSpin: allSpins.length  // Current spin number
  //           };
  //           return acc;
  //         }, {});
  //         console.log('Patterns with metadata:', patternsWithMeta);
  
  //         // Save to pattern history
  //         const existingPatterns = JSON.parse(localStorage.getItem('patternHistory') || '[]');
  //         const newPatterns = Object.values(patternsWithMeta);
  
  //         // Combine existing and new patterns, removing duplicates
  //         const updatedPatterns = [...existingPatterns];
  //         newPatterns.forEach(newPattern => {
  //           const isDuplicate = existingPatterns.some(existing =>
  //             existing.pattern === newPattern.pattern &&
  //             existing.patternType === newPattern.patternType
  //           );
  //           if (!isDuplicate) {
  //             updatedPatterns.push(newPattern);
  //           }
  //         });
  
  //         localStorage.setItem('patternHistory', JSON.stringify(updatedPatterns));
  
  //         // Trigger custom event to notify pattern history was updated
  //         window.dispatchEvent(new Event('patternHistoryUpdated'));
  
  //         // Save pending patterns for modal and show modal
  //         localStorage.setItem('pendingPatterns', JSON.stringify(patternsWithMeta));
  //         setPatternMatches(patternsWithMeta);
  //         console.log('Set pattern matches, waiting for modal interaction');
  //       } else {
  //         // No patterns found, safe to reset and navigate
  //         resetForm();
  //         navigateToDefaultScreen();
  //       }
  //     } else {
  //       // Pattern detection disabled, just reset and navigate
  //       resetForm();
  //       navigateToDefaultScreen();
  //     }
  //   } catch (error) {
  //     console.error("Error saving spin:", error);
  //     alert("Error saving spin. Please try again.");
  //   }
  // };
    const handleModalClose = () => {
      console.log('Modal closing, resetting and navigating');
      setPatternMatches(null);
      resetForm();
      navigateToDefaultScreen();
    };

    const resetForm = () => {
      setSpinData({
        session_id: session ? session._id : "",
        spin_value: "",
        rbOutcome: "---",
        oeOutcome: "---",
        hlOutcome: "---",
        dozOutcome: "---",
        colOutcome: "---",
        neighborsOutcome: "---",
        rbBet: 0,
        oeBet: 0,
        hlBet: 0,
        dozBet: 0,
        colBet: 0,
        neighborsBet: 0,
      });
      setCurrentStrategy("");
      setIsStrategySelected(false);
      setSummaryVisible(false);
    };

    const handleCustomBetClick = () => {
      const customBet = prompt('Enter custom bet amount:');
      if (customBet && !isNaN(customBet) && Number(customBet) > 0) {
        setCustomBetAmount(Number(customBet)); // Set custom bet amount
        setSpinData((prevData) => ({
          ...prevData,
          [`${currentStrategy}Bet`]: Number(customBet), // Set custom bet value
        }));
        setSummaryVisible(true); // Show summary after setting custom bet
        setIsStrategySelected(true); // Ensure strategy is selected to show buttons
      } else {
        alert('Please enter a valid number.');
      }
    };

    const navigateToDefaultScreen = () => {
      // If there was a bet, navigate to that strategy's tab
      if (spinData[`${currentStrategy}Bet`]) {
        const strategyToTabMap = {
          'rb': 'RedBlack',
          'oe': 'OddEven',
          'hl': 'HighLow',
          'doz': 'Dozens',
          'col': 'Columns',
          'rep': 'Rep',
          'neighbors': 'Neb'
        };
        navigate(`/stats?tab=${strategyToTabMap[currentStrategy]}`);
      } else {
        // If no bet was placed, use the default screen setting
        const defaultScreen = localStorage.getItem("defaultScreen") || "RedBlack";
        navigate(`/stats?tab=${defaultScreen}`);
      }
    };

    const handleBack = () => {
      resetForm();
    };

    const getColorClass = (spinValue) => {
      const redNumbers = [
        "1",
        "3",
        "5",
        "7",
        "9",
        "12",
        "14",
        "16",
        "18",
        "19",
        "21",
        "23",
        "25",
        "27",
        "30",
        "32",
        "34",
        "36",
      ];
      const blackNumbers = [
        "2",
        "4",
        "6",
        "8",
        "10",
        "11",
        "13",
        "15",
        "17",
        "20",
        "22",
        "24",
        "26",
        "28",
        "29",
        "31",
        "33",
        "35",
      ];
      if (spinValue === "0" || spinValue === "00") return "green";
      if (redNumbers.includes(spinValue)) return "red";
      if (blackNumbers.includes(spinValue)) return "black";
      return "";
    };


    const getSummaryText = () => {
      const getOutcomeText = (outcome, bet, prefix) => {
        if (!outcome || outcome === "---" || outcome === "No Bet") return "";
        return `<b>${prefix}</b> (${outcome.charAt(0).toUpperCase()}) ${bet === 'custom' ? customBetAmount : bet
          }`;
      };

      if (
        spinData.rbOutcome === "No Bet" &&
        spinData.oeOutcome === "No Bet" &&
        spinData.hlOutcome === "No Bet" &&
        spinData.dozOutcome === "No Bet" &&
        spinData.colOutcome === "No Bet" &&
        spinData.repOutcome === "No Bet" &&
        spinData.neighborsOutcome === "No Bet"
      ) {
        return "No Bet";
      }

      const texts = [
        getOutcomeText(spinData.rbOutcome, spinData.rbBet, "R/B"),
        getOutcomeText(spinData.oeOutcome, spinData.oeBet, "O/E"),
        getOutcomeText(spinData.hlOutcome, spinData.hlBet, "H/L"),
        getOutcomeText(spinData.dozOutcome, spinData.dozBet, "Doz"),
        getOutcomeText(spinData.colOutcome, spinData.colBet, "Col"),
        getOutcomeText(spinData.repOutcome, spinData.repBet, "Rep"),
        getOutcomeText(spinData.neighborsOutcome, spinData.neighborsBet, "Neighbors")
      ];

      return texts.filter(text => text).join(", ");
    };

    // Add this right before the return statement
    console.log('Rendering Spin component, patternMatches:', patternMatches);
    return (
      <div className="spin-page">
        {spinData.spin_value && (
          <button className="back-button" onClick={handleBack}>
            Back
          </button>
        )}

        {!spinData.spin_value && (
          <>
            <div className="zeros-container">
              <div className="first-row-button-container">
                <button
                  key="0"
                  className={`spin-button first-row-button ${getColorClass("0")}`}
                  onClick={() => handleSpinValueChange("0")}
                >
                  <span>0</span>
                </button>
              </div>
              <div className="first-row-button-container">
                <button
                  key="00"
                  className={`spin-button first-row-button ${getColorClass("00")}`}
                  onClick={() => handleSpinValueChange("00")}
                >
                  <span>00</span>
                </button>
              </div>
            </div>

            <div className="other-numbers-container">
              {Array.from({ length: 36 }, (_, i) => (i + 1).toString()).map(
                (number, index) => (
                  <React.Fragment key={number}>
                    <button
                      className={`spin-button ${getColorClass(number)}`}
                      onClick={() => handleSpinValueChange(number)}
                    >
                      <span>{number}</span>
                    </button>
                    {(index + 1) % 12 === 0 && index !== 35 && (
                      <hr className="divider" />
                    )}
                  </React.Fragment>
                )
              )}
            </div>
          </>
        )}

        {spinData.spin_value && (
          <>
            <div className="selected-spin-container">
              <button
                className={`selected-spin ${getColorClass(spinData.spin_value)}`}
              >
                <span>{spinData.spin_value}</span>
              </button>
            </div>
            <button onClick={handleNoBet} className="no-bet-button">
              No Bet
            </button>
            <div className="strategy-buttons">
              <button
                onClick={() => handleStrategyChange("rb")}
                className={`strategy-button ${currentStrategy === "rb" ? "active" : ""}`}
              >
                R/B
              </button>
              <button
                onClick={() => handleStrategyChange("oe")}
                className={`strategy-button ${currentStrategy === "oe" ? "active" : ""}`}
              >
                O/E
              </button>
              <button
                onClick={() => handleStrategyChange("hl")}
                className={`strategy-button ${currentStrategy === "hl" ? "active" : ""}`}
              >
                H/L
              </button>
              <button
                onClick={() => handleStrategyChange("doz")}
                className={`strategy-button ${currentStrategy === "doz" ? "active" : ""}`}
              >
                Doz
              </button>
              <button
                onClick={() => handleStrategyChange("col")}
                className={`strategy-button ${currentStrategy === "col" ? "active" : ""}`}
              >
                Col
              </button>
              <button
                onClick={() => handleStrategyChange("rep")}
                className={`strategy-button ${currentStrategy === "rep" ? "active" : ""}`}
              >
                Rep
              </button>
              <button
                onClick={() => handleStrategyChange("neighbors")}
                className={`strategy-button ${currentStrategy === "neighbors" ? "active" : ""}`}
              >
                Neb
              </button>
            </div>
            {isStrategySelected && currentStrategy !== "nb" && (
              <div className="outcome-buttons-container">
                <button
                  onClick={() => handleOutcomeChange("win")}
                  className={`outcome-button win-button ${spinData[`${currentStrategy}Outcome`] === "win" ? "outlined" : ""
                    }`}
                  disabled={
                    (spinData.spin_value === "0" || spinData.spin_value === "00") &&
                    currentStrategy !== "rep" &&
                    currentStrategy !== "neighbors"
                  }
                >
                  Win
                </button>
                <button
                  onClick={() => handleOutcomeChange("loss")}
                  className={`outcome-button loss-button ${spinData[`${currentStrategy}Outcome`] === "loss" ? "outlined" : ""
                    }`}
                >
                  Loss
                </button>
              </div>
            )}
            {isStrategySelected &&
              currentStrategy !== "nb" &&
              spinData[currentStrategy + "Outcome"] &&
              spinData[currentStrategy + "Outcome"] !== "---" && (
                <div className="bet-amount-container">
                  <div className="bet-grid">
                    {[...Array(BET_OPTIONS_COUNT)].map((_, i) => (
                      <button
                        key={i + 1}
                        className={`bet-button ${spinData[currentStrategy + 'Outcome'] === 'win' ? 'green' : 'red'
                          } ${spinData[currentStrategy + 'Bet'] === i + 1 ? 'highlighted' : ''
                          }`}
                        onClick={() => handleBetAmount(i + 1)}
                      >
                        {i + 1}
                      </button>
                    ))}
                    <button
                      className={`bet-button custom-bet-button ${spinData[currentStrategy + 'Outcome'] === 'win' ? 'green' : 'red'
                        } ${spinData[currentStrategy + 'Bet'] === 'custom' ? 'highlighted' : ''}`}
                      onClick={handleCustomBetClick}
                    >
                      {customBetAmount !== null ? `${customBetAmount}` : '$$'}
                    </button>
                  </div>
                </div>
              )}


            {summaryVisible && (
              <div className="summary-text">
                <span dangerouslySetInnerHTML={{ __html: getSummaryText() }} />
              </div>
            )}
            {console.log('About to render modal, patternMatches:', patternMatches)}
            {patternMatches && (
              <PatternMatchModal
                patterns={patternMatches}
                onClose={handleModalClose}
              />
            )}
            {isStrategySelected &&
              currentStrategy !== "nb" &&
              spinData[currentStrategy + "Bet"] > 0 && (
                <div className="action-buttons">
                  <button className="cancel-button" onClick={resetForm}>
                    Cancel
                  </button>
                  <button className="save-button" onClick={handleSave}>
                    Save
                  </button>
                  {/* <button
                  onClick={() => setPatternMatches({
                    redBlack: {
                      pattern: 'BBBBB',
                      previousOccurrence: 0,
                      totalOccurrences: 2,
                      patternType: 'redBlack'
                    }
                  })}
                >
                  Test Modal
                </button> */}
                </div>
              )}
          </>
        )}
      </div>
    );
  };

  export default Spin;