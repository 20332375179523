import React, { useEffect, useState, useMemo } from "react";
import { useSession } from "../context/SessionContext";
import { useSpin } from "../context/SpinContext";
import styles from './Columns.module.css';
import sharedStyles from './Shared.css';
import UpdateSpinModal from '../components/UpdateSpinModal';

const calculateLookbackPercentages = (spins, criteria = {}) => {
  // Use thirds criteria with default values if criteria is undefined
  const lookback = (criteria.thirdsLookback || criteria.spinsToLookBack || 5);
  const highlightPercentage = (criteria.thirdsHighlight || criteria.startHighlightingAt || 0.49);
  const aboveOrBelow = (criteria.thirdsAboveBelow || criteria.aboveOrBelow || 'Above');

  return spins.map((spin, index) => {
    if (index < lookback - 1) {
      return { ...spin, highlightC1: false, highlightC2: false, highlightC3: false };
    }

    const lookbackSpins = spins.slice(index - lookback + 1, index + 1);
    const c1Count = lookbackSpins.filter(spin => parseInt(spin.spin_value) % 3 === 1).length;
    const c2Count = lookbackSpins.filter(spin => parseInt(spin.spin_value) % 3 === 2).length;
    const c3Count = lookbackSpins.filter(spin => parseInt(spin.spin_value) % 3 === 0 && parseInt(spin.spin_value) !== 0).length;
    const c1Percentage = Math.round((c1Count / lookback) * 100);
    const c2Percentage = Math.round((c2Count / lookback) * 100);
    const c3Percentage = Math.round((c3Count / lookback) * 100);

    let highlightC1 = false;
    let highlightC2 = false;
    let highlightC3 = false;
    const threshold = highlightPercentage * 100;

    if (aboveOrBelow === 'Above') {
      if (c1Percentage > threshold) highlightC1 = true;
      if (c2Percentage > threshold) highlightC2 = true;
      if (c3Percentage > threshold) highlightC3 = true;
    } else {
      if (c1Percentage < threshold) highlightC1 = true;
      if (c2Percentage < threshold) highlightC2 = true;
      if (c3Percentage < threshold) highlightC3 = true;
    }

    return { ...spin, c1Percentage, c2Percentage, c3Percentage, highlightC1, highlightC2, highlightC3 };
  });
};

const calculateRunningTotals = (spins) => {
    return spins.reduce((acc, spin, index) => {
      const previousTotal = index > 0 ? acc[index - 1].running_total : 0;
      const betAmount = spin.colBet || 0;
      // Fixed to check for 'win' and 'loss' instead of 'W' and 'L'
      const outcomeMultiplier = spin.colOutcome === 'win' ? 1 : spin.colOutcome === 'loss' ? -1 : 0;
      const running_total = previousTotal + betAmount * outcomeMultiplier;
      return [...acc, { ...spin, running_total }];
    }, []);
  };

const Columns = () => {
    const { session } = useSession();
    const { state: { spins, spinsFetched }, fetchSpins, updateSpin, deleteSpin } = useSpin();
    const [loading, setLoading] = useState(false);
    const [currentSpin, setCurrentSpin] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [criteria, setCriteria] = useState({
        // Default values for both old and new format
        thirdsLookback: 5,
        thirdsHighlight: 0.49,
        thirdsAboveBelow: 'Above',
        spinsToLookBack: 5,
        startHighlightingAt: 0.49,
        aboveOrBelow: 'Above'
    });

    useEffect(() => {
        try {
            const savedCriteria = JSON.parse(localStorage.getItem('analysisCriteria') || '{}');
            setCriteria(prev => ({
                ...prev,
                ...savedCriteria
            }));
        } catch (error) {
            console.error("Error parsing analysis criteria:", error);
        }
    }, []);

    useEffect(() => {
        if (session?._id && !loading && !spinsFetched) {
            setLoading(true);
            console.log("Fetching spins...");
            fetchSpins(session._id)
                .catch(error => {
                    console.error("Error fetching spins:", error);
                })
                .finally(() => setLoading(false));
        }
    }, [session?._id, loading, spinsFetched, fetchSpins]);

    useEffect(() => {
        if (spinsFetched && spins.length === 0) {
            console.log('No spins returned, stopping further API calls.');
        }
    }, [spins, spinsFetched]);

    const handleEditSpin = (spin) => {
        console.log("Editing spin:", spin);
        setCurrentSpin(spin);
        setShowModal(true);
    };

    const handleUpdateSpin = (updatedSpin) => {
        updateSpin(updatedSpin);
        setShowModal(false);
    };

    const handleDeleteSpin = (deletedSpin) => {
        deleteSpin(deletedSpin);
        setShowModal(false);
    };

    const getColor = useMemo(() => {
        return (spinValue) => {
            const spinValueInt = parseInt(spinValue, 10);
            if (spinValue === '0' || spinValue === '00') {
                return styles.green; // Green for 0 and 00
            }

            // Columns
            if (spinValueInt % 3 === 1) {
                return styles.c1; // Color for Column 1
            }
            if (spinValueInt % 3 === 2) {
                return styles.c2; // Color for Column 2
            }
            if (spinValueInt % 3 === 0 && spinValueInt !== 0) {
                return styles.c3; // Color for Column 3
            }

            return '';
        };
    }, []);

    const highlightedSpins = useMemo(() =>
        calculateLookbackPercentages(spins, criteria),
        [spins, criteria]
    );

    const spinsWithRunningTotals = useMemo(() => {
        const calculatedSpins = calculateRunningTotals(highlightedSpins);
        // Return a reversed copy of the array to show most recent spins at the top
        return [...calculatedSpins].reverse();
    }, [highlightedSpins]);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className={styles.historyTab}>
            <div className={styles.historyContent}>
                <div className={styles.spinHistoryGrid}>
                    <div className={styles.spinHistoryHeader}>
                        <div className={styles.headerNo}>No.</div>
                        <div className={styles.headerSpin}>Spin</div>
                        <div className={styles.headerC1}>C1</div>
                        <div className={styles.headerC2}>C2</div>
                        <div className={styles.headerC3}>C3</div>
                        <div className={styles.headerWl}>W/L</div>
                        <div className={styles.headerBet}>Bet</div>
                        <div className={styles.headerRt}>RT</div>
                    </div>
                    {spinsWithRunningTotals.map((spin, index) => {
                        // Calculate the original spin number by reverting the index
                        const originalSpinNumber = spins.length - index;
                        
                        return (
                            <div key={spin._id} className={`${styles.spinRecord} ${getColor(spin.spin_value)}`} onClick={() => handleEditSpin(spin)}>
                                <div className={styles.spinNumber}>{originalSpinNumber}</div>
                                <div className={`${styles.spinValue} ${getColor(spin.spin_value)}`}>
                                    {spin.spin_value}
                                </div>
                                <div className={`${styles.c1Column} ${spin.highlightC1 ? styles.highlight : ''}`}>
                                    {spin.c1Percentage}%
                                </div>
                                <div className={`${styles.c2Column} ${spin.highlightC2 ? styles.highlight : ''}`}>
                                    {spin.c2Percentage}%
                                </div>
                                <div className={`${styles.c3Column} ${spin.highlightC3 ? styles.highlight : ''}`}>
                                    {spin.c3Percentage}%
                                </div>
                                <div className={`${styles.outcome} ${styles[spin.colOutcome]}`}>
                                {spin.colOutcome === 'win' ? 'W' : spin.colOutcome === 'loss' ? 'L' : spin.colOutcome === 'no bet' ? '---' : spin.colOutcome}
                                </div>
                                <div className={styles.betAmount}>
                                    {spin.colOutcome === 'NB'
                                        ? ''
                                        : `${spin.colOutcome === 'L' ? '-' : ''}${spin.colBet}`}
                                </div>
                                <div className={styles.runningTotal}>{spin.running_total}</div>
                            </div>
                        );
                    })}
                </div>
            </div>
            {currentSpin && (
                <UpdateSpinModal
                    show={showModal}
                    handleClose={() => setShowModal(false)}
                    spin={currentSpin}
                    onUpdate={handleUpdateSpin}
                    onDelete={handleDeleteSpin}
                    betType="col" // Specify the bet type
                />
            )}
        </div>
    );
};

export default Columns;