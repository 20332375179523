// import React, { useState, useEffect, useMemo, useCallback } from "react";
// import axios from 'axios';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
// import { useSpin } from '../context/SpinContext';
// import styles from './UpdateSpinModal.module.css';

// const backendUrl = process.env.REACT_APP_BACKEND_URL;

// const UpdateSpinModal = ({ show, handleClose, spin, onUpdate, betType }) => {
//   const { updateSpin, fetchSpins } = useSpin();
//   const [spinValue, setSpinValue] = useState('');
//   const [outcome, setOutcome] = useState('');
//   const [betAmt, setBetAmt] = useState('');
//   const [showSpinGrid, setShowSpinGrid] = useState(false);
//   const [errors, setErrors] = useState({});
//   const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
//   const [customBetAmount, setCustomBetAmount] = useState(null);

//   const resetValues = useCallback(() => {
//     setSpinValue(spin.spin_value);
//     let initialBetAmt = '';
//     let initialOutcome = '---';
    
//     if (betType === 'rep' || betType === 'neighbors') {
//       if (spin[`${betType}Bet`] && spin[`${betType}Bet`] !== 0) {
//         initialOutcome = spin[`${betType}Outcome`] === 'win' ? 'W' :
//                         spin[`${betType}Outcome`] === 'loss' ? 'L' : 
//                         spin[`${betType}Outcome`];
//         initialBetAmt = Math.abs(spin[`${betType}Bet`]).toString();
//       }
//     } else {
//       initialOutcome = spin[`${betType}Outcome`] === 'no bet' ? '---' : 
//                       spin[`${betType}Outcome`] === 'win' ? 'W' :
//                       spin[`${betType}Outcome`] === 'loss' ? 'L' :
//                       spin[`${betType}Outcome`];
//       initialBetAmt = (spin[`${betType}Outcome`] === 'no bet' || spin[`${betType}Bet`] === 0) 
//         ? '' 
//         : spin[`${betType}Bet`].toString();
//     }

//     setOutcome(initialOutcome);
    
//     if (parseInt(initialBetAmt) > 39) {
//       setCustomBetAmount(parseInt(initialBetAmt));
//       setBetAmt('custom');
//     } else {
//       setBetAmt(initialBetAmt);
//       setCustomBetAmount(null);
//     }

//     setErrors({});
//     setShowSpinGrid(false);
//   }, [spin, betType]);

//   useEffect(() => {
//     if (show && spin) {
//       resetValues();
//     }
//   }, [show, spin, betType, resetValues]);

//   const handleSpinValueChange = useCallback((value) => {
//     setSpinValue(value);
//     setShowSpinGrid(false);
//   }, []);

//   const handleCustomBetClick = useCallback(() => {
//     if (!outcome || outcome === '---') {
//       setErrors({ outcome: "Please select Win or Loss first" });
//       return;
//     }

//     const customBet = prompt('Enter custom bet amount:', customBetAmount?.toString() || '');
//     if (customBet && !isNaN(customBet) && Number(customBet) > 0) {
//       setCustomBetAmount(Number(customBet));
//       setBetAmt('custom');
//       setErrors(prev => ({ ...prev, betAmount: null }));
//     } else if (customBet !== null) {
//       alert('Please enter a valid number greater than 0.');
//     }
//   }, [customBetAmount, outcome]);

//   const handleBetAmount = useCallback((amount) => {
//     if (!outcome || outcome === '---') {
//       setErrors({ outcome: "Please select Win or Loss first" });
//       return;
//     }

//     if (amount === 'custom') {
//       handleCustomBetClick();
//     } else {
//       setBetAmt(amount);
//       setCustomBetAmount(null);
//     }
//     setErrors(prev => ({ ...prev, betAmount: null }));
//   }, [outcome, handleCustomBetClick]);

//   const handleOutcomeChange = useCallback((newOutcome) => {
//     if (newOutcome === 'NB') {
//       setBetAmt('0');
//       setOutcome('---');
//     } else {
//       setOutcome(newOutcome);
//       if (betAmt === '0') {
//         setBetAmt('');
//       }
//     }
//     setErrors({});
//   }, [betAmt]);

//   const isValidBet = useMemo(() => {
//     if (outcome === '---') return true;
//     if (outcome === 'W' || outcome === 'L') {
//       const betAmount = betAmt === 'custom' ? customBetAmount : parseFloat(betAmt);
//       return betAmount > 0;
//     }
//     return false;
//   }, [outcome, betAmt, customBetAmount]);

//   const handleSave = useCallback(async () => {
//     if (!outcome || (outcome !== '---' && outcome !== 'W' && outcome !== 'L')) {
//       setErrors(prev => ({ ...prev, outcome: "Please select Win, Loss, or No Bet" }));
//       return;
//     }
//     if (!isValidBet) {
//       setErrors(prev => ({ ...prev, betAmount: "Bet amount must be greater than 0 for Win or Loss" }));
//       return;
//     }

//     try {
//       const updateData = {
//         spinId: spin._id,
//         spin_value: spinValue,
//       };

//       const rawBetAmount = betAmt === 'custom' ? customBetAmount : parseFloat(betAmt) || 0;

//       if (betType === 'rep' || betType === 'neighbors') {
//         const finalBetAmount = outcome === 'L' ? -Math.abs(rawBetAmount) : Math.abs(rawBetAmount);
//         updateData[`${betType}Outcome`] = outcome;
//         updateData[`${betType}Bet`] = outcome === '---' ? 0 : finalBetAmount;
//       } else {
//         updateData[`${betType}Outcome`] = outcome;
//         updateData[`${betType}Bet`] = outcome === '---' ? 0 : rawBetAmount;
//       }

//       const response = await axios.put(`${backendUrl}/api/spins/${spin._id}`, updateData);

//       if (response.status === 200) {
//         updateSpin(response.data);
//         await fetchSpins(spin.session_id);
//         handleClose();
//         if (onUpdate) {
//           onUpdate(response.data);
//         }
//       }
//     } catch (error) {
//       console.error("Failed to update spin:", error);
//       setErrors(prev => ({ ...prev, submit: "Failed to update spin. Please try again." }));
//     }
//   }, [spin._id, spin.session_id, spinValue, betType, outcome, betAmt, customBetAmount, updateSpin, fetchSpins, handleClose, onUpdate, isValidBet]);

//   const handleDelete = useCallback(() => {
//     setShowDeleteConfirmation(true);
//   }, []);

//   const confirmDelete = useCallback(async () => {
//     try {
//       await axios.delete(`${backendUrl}/api/spins/${spin._id}`);
//       await fetchSpins(spin.session_id);
//       handleClose();
//     } catch (error) {
//       console.error("Failed to delete spin:", error);
//       setErrors(prev => ({ ...prev, delete: "Failed to delete spin. Please try again." }));
//     }
//     setShowDeleteConfirmation(false);
//   }, [spin._id, spin.session_id, fetchSpins, handleClose]);

//   const cancelDelete = useCallback(() => {
//     setShowDeleteConfirmation(false);
//   }, []);

//   const getSpinButtonColor = useCallback((value) => {
//     const redNumbers = ['1', '3', '5', '7', '9', '12', '14', '16', '18', '19', '21', '23', '25', '27', '30', '32', '34', '36'];
//     if (value === '0' || value === '00') return styles.greenButton;
//     return redNumbers.includes(value) ? styles.redButton : styles.blackButton;
//   }, []);

//   const getBetButtonClass = useCallback((value) => {
//     let classes = [styles.betButton];
//     if (betAmt === value) classes.push(styles.selected);
//     if (outcome === 'L') classes.push(styles.redButton);
//     return classes.join(' ');
//   }, [betAmt, outcome]);

//   return (
//     show && (
//       <div className={styles["edit-spin-modal"]}>
//         <div className={styles["edit-spin-content"]}>
//           <div className={styles["scrollable-content"]}>
//             <div className={styles["modal-header"]}>
//               <h3>Edit Spin</h3>
//               {!showSpinGrid && (
//                 <div 
//                   className={styles["spin-value-display"]} 
//                   onClick={() => setShowSpinGrid(true)}
//                 >
//                   Current Spin: {spinValue}
//                 </div>
//               )}
//             </div>

//             {showSpinGrid ? (
//               <div className={styles["spin-grid"]}>
//                 <div className={styles["zeros-row"]}>
//                   {['0', '00'].map(num => (
//                     <button
//                       key={num}
//                       className={`${styles.spinButton} ${getSpinButtonColor(num)}`}
//                       onClick={() => handleSpinValueChange(num)}
//                     >
//                       {num}
//                     </button>
//                   ))}
//                 </div>
//                 <div className={styles["numbers-grid"]}>
//                   {Array.from({ length: 36 }, (_, i) => (i + 1).toString()).map(num => (
//                     <button
//                       key={num}
//                       className={`${styles.spinButton} ${getSpinButtonColor(num)}`}
//                       onClick={() => handleSpinValueChange(num)}
//                     >
//                       {num}
//                     </button>
//                   ))}
//                 </div>
//               </div>
//             ) : (
//               <>
//                 <div className={styles["outcome-buttons-container"]}>
//                   <button
//                     className={`${styles["outcome-button"]} ${outcome === 'W' ? styles["selected-win"] : ''}`}
//                     onClick={() => handleOutcomeChange('W')}
//                   >
//                     Win
//                   </button>
//                   <button
//                     className={`${styles["outcome-button"]} ${outcome === 'L' ? styles["selected-loss"] : ''}`}
//                     onClick={() => handleOutcomeChange('L')}
//                   >
//                     Loss
//                   </button>
//                   <button
//                     className={`${styles["outcome-button"]} ${outcome === '---' ? styles["selected-nb"] : ''}`}
//                     onClick={() => handleOutcomeChange('NB')}
//                   >
//                     No Bet
//                   </button>
//                 </div>

//                 {errors.outcome && <div className={styles["error-message"]}>{errors.outcome}</div>}
                
//                 {outcome && outcome !== '---' && (
//                   <div className={styles["grid-container"]}>
//                     <div className={styles["grid"]}>
//                       {[...Array(60)].map((_, i) => (
//                         <button
//                           key={i + 1}
//                           className={getBetButtonClass((i + 1).toString())}
//                           onClick={() => handleBetAmount((i + 1).toString())}
//                         >
//                           {i + 1}
//                         </button>
//                       ))}
//                       <button
//                         className={getBetButtonClass('custom')}
//                         onClick={() => handleBetAmount('custom')}
//                       >
//                         {customBetAmount !== null ? `${customBetAmount}` : '$$'}
//                       </button>
//                     </div>
//                   </div>
//                 )}

//                 {errors.betAmount && <div className={styles["error-message"]}>{errors.betAmount}</div>}
//               </>
//             )}
//           </div>

//           <div className={styles["button-group"]}>
//             <button className={styles["btn-cancel"]} onClick={handleClose}>
//               Cancel
//             </button>
//             {!showSpinGrid && (
//               <>
//                 <button 
//                   className={styles["btn-update"]} 
//                   onClick={handleSave}
//                   disabled={!isValidBet}
//                 >
//                   Save
//                 </button>
//                 <button className={styles["btn-delete"]} onClick={handleDelete} aria-label="Delete">
//                   <FontAwesomeIcon icon={faTrashAlt} />
//                 </button>
//               </>
//             )}
//           </div>
//         </div>

//         {showDeleteConfirmation && (
//           <div className={styles["delete-confirmation-modal"]}>
//             <div className={styles["delete-confirmation-content"]}>
//               <p>Are you sure you want to delete this spin?</p>
//               <div className={styles["delete-confirmation-buttons"]}>
//                 <button onClick={confirmDelete} className={styles["btn-confirm-delete"]}>Yes, Delete</button>
//                 <button onClick={cancelDelete} className={styles["btn-cancel-delete"]}>No, Cancel</button>
//               </div>
//             </div>
//           </div>
//         )}
//       </div>
//     )
//   );
// };

// export default UpdateSpinModal;

import React, { useState, useEffect, useMemo, useCallback } from "react";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useSpin } from '../context/SpinContext';
import styles from './UpdateSpinModal.module.css';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const UpdateSpinModal = ({ show, handleClose, spin, onUpdate, betType }) => {
  const { updateSpin, fetchSpins } = useSpin();
  const [spinValue, setSpinValue] = useState('');
  const [outcome, setOutcome] = useState('');
  const [betAmt, setBetAmt] = useState('');
  const [showSpinGrid, setShowSpinGrid] = useState(false);
  const [errors, setErrors] = useState({});
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [customBetAmount, setCustomBetAmount] = useState(null);

  const resetValues = useCallback(() => {
    setSpinValue(spin.spin_value);
    let initialBetAmt = '';
    let initialOutcome = '---';
    
    if (betType === 'rep' || betType === 'neighbors') {
      if (spin[`${betType}Bet`] && spin[`${betType}Bet`] !== 0) {
        initialOutcome = spin[`${betType}Outcome`] === 'win' ? 'W' :
                        spin[`${betType}Outcome`] === 'loss' ? 'L' : 
                        spin[`${betType}Outcome`];
        // Always use absolute value for display purposes
        initialBetAmt = Math.abs(spin[`${betType}Bet`]).toString();
      }
    } else {
      initialOutcome = spin[`${betType}Outcome`] === 'no bet' ? '---' : 
                      spin[`${betType}Outcome`] === 'win' ? 'W' :
                      spin[`${betType}Outcome`] === 'loss' ? 'L' :
                      spin[`${betType}Outcome`];
      initialBetAmt = (spin[`${betType}Outcome`] === 'no bet' || spin[`${betType}Bet`] === 0) 
        ? '' 
        : spin[`${betType}Bet`].toString();
    }

    setOutcome(initialOutcome);
    
    if (parseInt(initialBetAmt) > 39) {
      setCustomBetAmount(parseInt(initialBetAmt));
      setBetAmt('custom');
    } else {
      setBetAmt(initialBetAmt);
      setCustomBetAmount(null);
    }

    setErrors({});
    setShowSpinGrid(false);
  }, [spin, betType]);

  useEffect(() => {
    if (show && spin) {
      resetValues();
    }
  }, [show, spin, betType, resetValues]);

  const handleSpinValueChange = useCallback((value) => {
    setSpinValue(value);
    setShowSpinGrid(false);
  }, []);

  const handleCustomBetClick = useCallback(() => {
    if (!outcome || outcome === '---') {
      setErrors({ outcome: "Please select Win or Loss first" });
      return;
    }

    const customBet = prompt('Enter custom bet amount:', customBetAmount?.toString() || '');
    if (customBet && !isNaN(customBet) && Number(customBet) > 0) {
      setCustomBetAmount(Number(customBet));
      setBetAmt('custom');
      setErrors(prev => ({ ...prev, betAmount: null }));
    } else if (customBet !== null) {
      alert('Please enter a valid number greater than 0.');
    }
  }, [customBetAmount, outcome]);

  const handleBetAmount = useCallback((amount) => {
    if (!outcome || outcome === '---') {
      setErrors({ outcome: "Please select Win or Loss first" });
      return;
    }

    if (amount === 'custom') {
      handleCustomBetClick();
    } else {
      setBetAmt(amount);
      setCustomBetAmount(null);
    }
    setErrors(prev => ({ ...prev, betAmount: null }));
  }, [outcome, handleCustomBetClick]);

  const handleOutcomeChange = useCallback((newOutcome) => {
    if (newOutcome === 'NB') {
      setBetAmt('0');
      setOutcome('---');
    } else {
      setOutcome(newOutcome);
      if (betAmt === '0') {
        setBetAmt('');
      }
    }
    setErrors({});
  }, [betAmt]);

  const isValidBet = useMemo(() => {
    if (outcome === '---') return true;
    if (outcome === 'W' || outcome === 'L') {
      const betAmount = betAmt === 'custom' ? customBetAmount : parseFloat(betAmt);
      return betAmount > 0;
    }
    return false;
  }, [outcome, betAmt, customBetAmount]);

  const handleSave = useCallback(async () => {
    if (!outcome || (outcome !== '---' && outcome !== 'W' && outcome !== 'L')) {
      setErrors(prev => ({ ...prev, outcome: "Please select Win, Loss, or No Bet" }));
      return;
    }
    if (!isValidBet) {
      setErrors(prev => ({ ...prev, betAmount: "Bet amount must be greater than 0 for Win or Loss" }));
      return;
    }

    try {
      const updateData = {
        spinId: spin._id,
        spin_value: spinValue,
      };

      const rawBetAmount = betAmt === 'custom' ? customBetAmount : parseFloat(betAmt) || 0;

      // Important fix: Don't apply negative values based on outcome for Rep and Neighbors
      // Instead, always use positive values and let the backend/display logic handle signs
      if (betType === 'rep' || betType === 'neighbors') {
        // For rep and neighbors, we store positive values regardless of outcome
        // The display logic will handle showing negative values for losses
        updateData[`${betType}Outcome`] = outcome === 'W' ? 'win' : outcome === 'L' ? 'loss' : '---';
        updateData[`${betType}Bet`] = outcome === '---' ? 0 : Math.abs(rawBetAmount);
      } else {
        // For other bet types, we keep the original behavior
        updateData[`${betType}Outcome`] = outcome === 'W' ? 'win' : outcome === 'L' ? 'loss' : '---';
        updateData[`${betType}Bet`] = outcome === '---' ? 0 : rawBetAmount;
      }

      console.log("Sending update with data:", updateData);
      const response = await axios.put(`${backendUrl}/api/spins/${spin._id}`, updateData);

      if (response.status === 200) {
        console.log("Update successful, response:", response.data);
        updateSpin(response.data);
        
        // Important: Fetch spins after update to recalculate running totals
        await fetchSpins(spin.session_id);
        
        handleClose();
        if (onUpdate) {
          onUpdate(response.data);
        }
      }
    } catch (error) {
      console.error("Failed to update spin:", error);
      setErrors(prev => ({ ...prev, submit: "Failed to update spin. Please try again." }));
    }
  }, [spin._id, spin.session_id, spinValue, betType, outcome, betAmt, customBetAmount, updateSpin, fetchSpins, handleClose, onUpdate, isValidBet]);

  const handleDelete = useCallback(() => {
    setShowDeleteConfirmation(true);
  }, []);

  const confirmDelete = useCallback(async () => {
    try {
      await axios.delete(`${backendUrl}/api/spins/${spin._id}`);
      await fetchSpins(spin.session_id);
      handleClose();
    } catch (error) {
      console.error("Failed to delete spin:", error);
      setErrors(prev => ({ ...prev, delete: "Failed to delete spin. Please try again." }));
    }
    setShowDeleteConfirmation(false);
  }, [spin._id, spin.session_id, fetchSpins, handleClose]);

  const cancelDelete = useCallback(() => {
    setShowDeleteConfirmation(false);
  }, []);

  const getSpinButtonColor = useCallback((value) => {
    const redNumbers = ['1', '3', '5', '7', '9', '12', '14', '16', '18', '19', '21', '23', '25', '27', '30', '32', '34', '36'];
    if (value === '0' || value === '00') return styles.greenButton;
    return redNumbers.includes(value) ? styles.redButton : styles.blackButton;
  }, []);

  const getBetButtonClass = useCallback((value) => {
    let classes = [styles.betButton];
    if (betAmt === value) classes.push(styles.selected);
    if (outcome === 'L') classes.push(styles.redButton);
    return classes.join(' ');
  }, [betAmt, outcome]);

  return (
    show && (
      <div className={styles["edit-spin-modal"]}>
        <div className={styles["edit-spin-content"]}>
          <div className={styles["scrollable-content"]}>
            <div className={styles["modal-header"]}>
              <h3>Edit Spin</h3>
              {!showSpinGrid && (
                <div 
                  className={styles["spin-value-display"]} 
                  onClick={() => setShowSpinGrid(true)}
                >
                  Current Spin: {spinValue}
                </div>
              )}
            </div>

            {showSpinGrid ? (
              <div className={styles["spin-grid"]}>
                <div className={styles["zeros-row"]}>
                  {['0', '00'].map(num => (
                    <button
                      key={num}
                      className={`${styles.spinButton} ${getSpinButtonColor(num)}`}
                      onClick={() => handleSpinValueChange(num)}
                    >
                      {num}
                    </button>
                  ))}
                </div>
                <div className={styles["numbers-grid"]}>
                  {Array.from({ length: 36 }, (_, i) => (i + 1).toString()).map(num => (
                    <button
                      key={num}
                      className={`${styles.spinButton} ${getSpinButtonColor(num)}`}
                      onClick={() => handleSpinValueChange(num)}
                    >
                      {num}
                    </button>
                  ))}
                </div>
              </div>
            ) : (
              <>
                <div className={styles["outcome-buttons-container"]}>
                  <button
                    className={`${styles["outcome-button"]} ${outcome === 'W' ? styles["selected-win"] : ''}`}
                    onClick={() => handleOutcomeChange('W')}
                  >
                    Win
                  </button>
                  <button
                    className={`${styles["outcome-button"]} ${outcome === 'L' ? styles["selected-loss"] : ''}`}
                    onClick={() => handleOutcomeChange('L')}
                  >
                    Loss
                  </button>
                  <button
                    className={`${styles["outcome-button"]} ${outcome === '---' ? styles["selected-nb"] : ''}`}
                    onClick={() => handleOutcomeChange('NB')}
                  >
                    No Bet
                  </button>
                </div>

                {errors.outcome && <div className={styles["error-message"]}>{errors.outcome}</div>}
                
                {outcome && outcome !== '---' && (
                  <div className={styles["grid-container"]}>
                    <div className={styles["grid"]}>
                      {[...Array(60)].map((_, i) => (
                        <button
                          key={i + 1}
                          className={getBetButtonClass((i + 1).toString())}
                          onClick={() => handleBetAmount((i + 1).toString())}
                        >
                          {i + 1}
                        </button>
                      ))}
                      <button
                        className={getBetButtonClass('custom')}
                        onClick={() => handleBetAmount('custom')}
                      >
                        {customBetAmount !== null ? `${customBetAmount}` : '$$'}
                      </button>
                    </div>
                  </div>
                )}

                {errors.betAmount && <div className={styles["error-message"]}>{errors.betAmount}</div>}
              </>
            )}
          </div>

          <div className={styles["button-group"]}>
            <button className={styles["btn-cancel"]} onClick={handleClose}>
              Cancel
            </button>
            {!showSpinGrid && (
              <>
                <button 
                  className={styles["btn-update"]} 
                  onClick={handleSave}
                  disabled={!isValidBet}
                >
                  Save
                </button>
                <button className={styles["btn-delete"]} onClick={handleDelete} aria-label="Delete">
                  <FontAwesomeIcon icon={faTrashAlt} />
                </button>
              </>
            )}
          </div>
        </div>

        {showDeleteConfirmation && (
          <div className={styles["delete-confirmation-modal"]}>
            <div className={styles["delete-confirmation-content"]}>
              <p>Are you sure you want to delete this spin?</p>
              <div className={styles["delete-confirmation-buttons"]}>
                <button onClick={confirmDelete} className={styles["btn-confirm-delete"]}>Yes, Delete</button>
                <button onClick={cancelDelete} className={styles["btn-cancel-delete"]}>No, Cancel</button>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  );
};

export default UpdateSpinModal;