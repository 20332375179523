import React, { useEffect, useState, useMemo } from "react";
import { useSession } from "../context/SessionContext";
import { useSpin } from "../context/SpinContext";
import styles from './OddEven.module.css';
import sharedStyles from './Shared.css';
import UpdateSpinModal from '../components/UpdateSpinModal';

// Updated function that correctly accepts a criteria object
const calculateLookbackPercentages = (spins, criteria = {}) => {
  // Use evenOdds criteria with default values if criteria is undefined
  const lookback = (criteria.evenOddsLookback || criteria.spinsToLookBack || 5);
  const highlightPercentage = (criteria.evenOddsHighlight || criteria.startHighlightingAt || 0.49);
  const aboveOrBelow = (criteria.evenOddsAboveBelow || criteria.aboveOrBelow || 'Above');

  return spins.map((spin, index) => {
    if (index < lookback - 1) {
      return { ...spin, highlightOdd: false, highlightEven: false };
    }

    const lookbackSpins = spins.slice(index - lookback + 1, index + 1);
    const oddCount = lookbackSpins.filter(spin => spin.spin_value !== '0' && spin.spin_value !== '00' && parseInt(spin.spin_value) % 2 !== 0).length;
    const evenCount = lookbackSpins.filter(spin => spin.spin_value !== '0' && spin.spin_value !== '00' && parseInt(spin.spin_value) % 2 === 0).length;
    const oddPercentage = Math.round((oddCount / lookback) * 100);
    const evenPercentage = Math.round((evenCount / lookback) * 100);

    let highlightOdd = false;
    let highlightEven = false;
    const threshold = highlightPercentage * 100;

    if (aboveOrBelow === 'Above') {
      if (oddPercentage > threshold) {
        highlightOdd = true;
      }
      if (evenPercentage > threshold) {
        highlightEven = true;
      }
    } else {
      if (oddPercentage < threshold) {
        highlightOdd = true;
      }
      if (evenPercentage < threshold) {
        highlightEven = true;
      }
    }

    return { ...spin, oddPercentage, evenPercentage, highlightOdd, highlightEven };
  });
};

const calculateRunningTotals = (spins) => {
  return spins.reduce((acc, spin, index) => {
    const previousTotal = index > 0 ? acc[index - 1].running_total : 0;
    const betAmount = spin.oeBet || 0;
    // Fixed to check for 'win' and 'loss' instead of 'W' and 'L'
    const outcomeMultiplier = spin.oeOutcome === 'win' ? 1 : spin.oeOutcome === 'loss' ? -1 : 0;
    const running_total = previousTotal + betAmount * outcomeMultiplier;
    return [...acc, { ...spin, running_total }];
  }, []);
};

const OddEven = () => {
  const { session } = useSession();
  const { state: { spins, spinsFetched }, fetchSpins, updateSpin, deleteSpin } = useSpin();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [currentSpin, setCurrentSpin] = useState(null);

  // Change this to a single criteria object
  const [criteria, setCriteria] = useState({
    evenOddsLookback: 5,
    evenOddsHighlight: 0.49,
    evenOddsAboveBelow: 'Above',
    spinsToLookBack: 5,
    startHighlightingAt: 0.49,
    aboveOrBelow: 'Above'
  });

  useEffect(() => {
    try {
      const savedCriteria = JSON.parse(localStorage.getItem('analysisCriteria') || '{}');
      setCriteria(prev => ({
        ...prev,
        ...savedCriteria
      }));
    } catch (error) {
      console.error("Error parsing analysis criteria:", error);
    }
  }, []);

  useEffect(() => {
    if (session?._id && !loading && !spinsFetched) {
      setLoading(true);
      console.log("Fetching spins...");
      fetchSpins(session._id)
        .catch(error => {
          console.error("Error fetching spins:", error);
          // Optionally, set an error state here
        })
        .finally(() => setLoading(false));
    }
  }, [session?._id, loading, spinsFetched, fetchSpins]);

  useEffect(() => {
    if (spinsFetched && spins.length === 0) {
      console.log('No spins returned, stopping further API calls.');
    }
  }, [spins, spinsFetched]);

  const handleEditSpin = (spin) => {
    console.log("Editing spin:", spin); // Log the spin data
    setCurrentSpin(spin);
    setShowModal(true);
  };

  const handleUpdateSpin = (updatedSpin) => {
    updateSpin(updatedSpin);
    setShowModal(false);
  };

  const handleDeleteSpin = (deletedSpin) => {
    deleteSpin(deletedSpin);
    setShowModal(false);
  };

  const getColor = useMemo(() => {
    return (spinValue) => {
      // console.log('Spin Value:', spinValue); // Log the spin value
      if (spinValue === '0' || spinValue === '00') {
        // console.log('Applying green class'); // Log when green class is applied
        return styles.green; // Green for 0 and 00
      }
      const spinValueInt = parseInt(spinValue, 10);
      if (spinValueInt % 2 !== 0) {
        // console.log('Applying odd class'); // Log when odd class is applied
        return styles.red; // Class for odd values
      }
      if (spinValueInt % 2 === 0) {
        // console.log('Applying even class'); // Log when even class is applied
        return styles.black; // Class for even values
      }
      return '';
    };
  }, []);

  // Pass the whole criteria object to the function
  const highlightedSpins = useMemo(() =>
    calculateLookbackPercentages(spins, criteria),
    [spins, criteria]
  );

  const spinsWithRunningTotals = useMemo(() => {
    const calculatedSpins = calculateRunningTotals(highlightedSpins);
    // Return a reversed copy of the array to show most recent spins at the top
    return [...calculatedSpins].reverse();
  }, [highlightedSpins]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className={styles.historyTab}>
      <div className={styles.historyContent}>
        <div className={styles.spinHistoryGrid}>
          <div className={styles.spinHistoryHeader}>
            <div className={styles.headerNo}>No.</div>
            <div className={styles.headerSpin}>Spin</div>
            <div className={styles.headerOdd}>Odd</div>
            <div className={styles.headerEven}>Even</div>
            <div className={styles.headerWl}>W/L</div>
            <div className={styles.headerBet}>Bet</div>
            <div className={styles.headerRt}>RT</div>
          </div>
          {spinsWithRunningTotals.map((spin, index) => {
            // Calculate the original spin number by reverting the index
            const originalSpinNumber = spins.length - index;
            
            return (
              <div key={spin._id} className={`${styles.spinRecord} ${getColor(spin.spin_value)}`} onClick={() => handleEditSpin(spin)}>
                <div className={styles.spinNumber}>{originalSpinNumber}</div>
                <div className={`${styles.spinValue} ${getColor(spin.spin_value)}`}>
                  {spin.spin_value}
                </div>
                <div className={`${styles.oddColumn} ${spin.highlightOdd ? styles.highlight : ''}`}>
                  {spin.oddPercentage}%
                </div>
                <div className={`${styles.evenColumn} ${spin.highlightEven ? styles.highlight : ''}`}>
                  {spin.evenPercentage}%
                </div>
                <div className={`${styles.outcome} ${styles[spin.oeOutcome]}`}>
                  {spin.oeOutcome === 'win' ? 'W' : spin.oeOutcome === 'loss' ? 'L' : spin.oeOutcome === 'no bet' ? '---' : spin.oeOutcome}
                </div>
                <div className={styles.betAmount}>
                  {spin.oeOutcome === 'NB'
                    ? ''
                    : `${spin.oeOutcome === 'L' ? '-' : ''}${spin.oeBet}`}
                </div>
                <div className={styles.runningTotal}>{spin.running_total}</div>
              </div>
            );
          })}
        </div>
      </div>
      {currentSpin && (
        <UpdateSpinModal
          show={showModal}
          handleClose={() => setShowModal(false)}
          spin={currentSpin}
          onUpdate={handleUpdateSpin}
          onDelete={handleDeleteSpin}
          betType="oe" // Specify the bet type
        />
      )}
    </div>
  );
};

export default OddEven;