// import React, { useEffect, useState } from 'react';
// import PatternMatchModal from '../components/PatternMatchModal';

// const PatternHandler = ({ onClose }) => {
//   const [patterns, setPatterns] = useState(null);
//   const [showModal, setShowModal] = useState(false);  // Add explicit modal state

//   useEffect(() => {
//     console.log("PatternHandler mounted");
//     const savedPatterns = localStorage.getItem('pendingPatterns');
//     console.log("Retrieved patterns from localStorage:", savedPatterns);
    
//     if (savedPatterns) {
//       try {
//         const parsedPatterns = JSON.parse(savedPatterns);
//         console.log("Parsed patterns:", parsedPatterns);
//         setPatterns(parsedPatterns);
//         setShowModal(true);  // Explicitly set modal to show
//         localStorage.removeItem('pendingPatterns');
//       } catch (error) {
//         console.error("Error parsing patterns:", error);
//       }
//     }
//   }, []);

//   const handleModalClose = () => {
//     console.log("Pattern modal closing");
//     setShowModal(false);
//     setPatterns(null);
//     if (onClose) onClose();
//   };

//   console.log("PatternHandler rendering, patterns:", patterns, "showModal:", showModal);

//   // Return modal if we have patterns AND showModal is true
//   return (patterns && showModal) ? (
//     <PatternMatchModal
//       show={true}  // Explicitly set show prop
//       patterns={patterns}
//       onClose={handleModalClose}
//     />
//   ) : null;
// };

// export default PatternHandler;

import React, { useEffect, useState } from 'react';
import PatternMatchModal from './PatternMatchModal';

const PatternHandler = ({ onClose }) => {
  const [patterns, setPatterns] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    console.log("PatternHandler mounted");
    
    const checkPendingPatterns = () => {
      try {
        const savedPatterns = localStorage.getItem('pendingPatterns');
        console.log("Retrieved pending patterns from localStorage:", savedPatterns);
        
        if (savedPatterns) {
          const parsedPatterns = JSON.parse(savedPatterns);
          console.log("Parsed pending patterns:", parsedPatterns);
          
          if (Object.keys(parsedPatterns).length > 0) {
            setPatterns(parsedPatterns);
            setShowModal(true);
            console.log("Set patterns and showing modal");
          }
        }
      } catch (error) {
        console.error("Error handling pending patterns:", error);
      }
    };
    
    // Check immediately on mount
    checkPendingPatterns();
    
    // Also listen for storage events in case patterns are added from another component
    const handleStorageEvent = (e) => {
      if (e.key === 'pendingPatterns' || e.key === null) {
        console.log("Storage event detected for pendingPatterns");
        checkPendingPatterns();
      }
    };
    
    window.addEventListener('storage', handleStorageEvent);
    
    return () => {
      window.removeEventListener('storage', handleStorageEvent);
    };
  }, []);

  const handleModalClose = () => {
    console.log("Pattern modal closing");
    localStorage.removeItem('pendingPatterns');
    setShowModal(false);
    setPatterns(null);
    if (onClose) onClose();
  };

  console.log("PatternHandler rendering, patterns:", patterns, "showModal:", showModal);

  if (!patterns || !showModal) {
    return null;
  }

  return (
    <PatternMatchModal
      patterns={patterns}
      onClose={handleModalClose}
    />
  );
};

export default PatternHandler;