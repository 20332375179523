// import React, { useState, useEffect } from 'react';
// import { BrowserRouter as Router, Routes, Route, NavLink } from 'react-router-dom';
// import { useSession } from './context/SessionContext';

// import Home from './pages/Home';
// import Spin from './pages/Spin';
// import History from './pages/History';
// import Stats from './pages/Stats';
// import Settings from './pages/Settings';
// import Sessions from './pages/Sessions';
// import Spins from './pages/Spins';
// import UploadSpins from './pages/UploadSpins';
// import EndSession from './components/EndSession';
// import ViewPreviousSession from './components/ViewPreviousSession';
// import PatternHistory from './components/PatternHistory';
// import './App.css';

// const App = () => {
//   const { session } = useSession();
//   const [showPatternHistory, setShowPatternHistory] = useState(false);
//   const [hasPatterns, setHasPatterns] = useState(false);
//   const [borderColor, setBorderColor] = useState('#061AFB'); // Default color

//   // Check for patterns in localStorage when app loads and when patterns might change
//   useEffect(() => {
//     const handleStorageChange = (e) => {
//       if (e.key === 'patternHistory' || e.key === null) {
//         checkForPatterns();
//       }
//     };
  
//     // Check initially
//     checkForPatterns();
  
//     // Add event listener for storage changes
//     window.addEventListener('storage', handleStorageChange);
//     window.addEventListener('patternHistoryUpdated', checkForPatterns);
  
//     return () => {
//       window.removeEventListener('storage', handleStorageChange);
//       window.removeEventListener('patternHistoryUpdated', checkForPatterns);
//     };
//   }, []);

//   const checkForPatterns = () => {
//     try {
//       const patterns = JSON.parse(localStorage.getItem('patternHistory') || '[]');
//       const hasValidPatterns = Array.isArray(patterns) && patterns.length > 0 && patterns.some(pattern => 
//         pattern.pattern && pattern.patternType && pattern.timestamp
//       );
//       setHasPatterns(hasValidPatterns);
//     } catch (error) {
//       console.error("Error checking patterns:", error);
//       setHasPatterns(false);
//     }
//   };
  
//   useEffect(() => {
//     const handleStorageChange = (e) => {
//       // If localStorage was cleared (e.key is null) or patternHistory was modified
//       if (e.key === null || e.key === 'patternHistory') {
//         checkForPatterns();
//       }
//     };
  
//     // Check initially
//     checkForPatterns();
  
//     // Add event listeners
//     window.addEventListener('storage', handleStorageChange);
//     window.addEventListener('patternHistoryUpdated', checkForPatterns);
//     // Add a new event listener specifically for session end
//     window.addEventListener('sessionEnded', () => {
//       setHasPatterns(false);
//       localStorage.removeItem('patternHistory');
//     });
  
//     return () => {
//       window.removeEventListener('storage', handleStorageChange);
//       window.removeEventListener('patternHistoryUpdated', checkForPatterns);
//       window.removeEventListener('sessionEnded', () => {
//         setHasPatterns(false);
//         localStorage.removeItem('patternHistory');
//       });
//     };
//   }, []);
  
//   // Function to handle color change and store it in local storage
//   const handleColorChange = (color) => {
//     setBorderColor(color);
//     localStorage.setItem('borderColor', color);
//     document.documentElement.style.setProperty('--border-color', color); // Set CSS variable
//   };

//   // Function to handle session ending
//   const handleEndSession = (note) => {
//     console.log('Session ended with note:', note);
//     // Add your logic to handle the session end
//   };

//   return (
//     <Router>
//       {hasPatterns && (
//         <div className="pattern-history-icon" onClick={() => setShowPatternHistory(true)}>
//           <i className="fas fa-clipboard"></i>
//         </div>
//       )}
//       <PatternHistory 
//         isOpen={showPatternHistory}
//         onClose={() => setShowPatternHistory(false)}
//       />
//       <div className="app-container">
//         <nav className="bottom-nav">
//           <ul>
//             <li>
//               <NavLink to="/" className={({ isActive }) => (isActive ? 'active' : '')}>
//                 <i className="fas fa-home"></i>
//               </NavLink>
//             </li>
//             <li>
//               <NavLink to="/spin" className={({ isActive }) => (isActive ? 'active' : '')}>
//                 <i className="fas fa-compact-disc"></i>
//               </NavLink>
//             </li>
//             <li>
//               <NavLink to="/stats" className={({ isActive }) => (isActive ? 'active' : '')}>
//                 <i className="fas fa-chart-bar"></i>
//               </NavLink>
//             </li>
//             <li>
//               <NavLink to="/settings" className={({ isActive }) => (isActive ? 'active' : '')}>
//                 <i className="fas fa-cog"></i>
//               </NavLink>
//             </li>
//           </ul>
//         </nav>
//         <Routes>
//           <Route path="/" element={<Home />} />
//           <Route path="/spin" element={session ? <Spin /> : <Settings defaultColor={borderColor} onColorChange={handleColorChange} />} />
//           <Route path="/history" element={session ? <History /> : <Settings defaultColor={borderColor} onColorChange={handleColorChange} />} />
//           <Route path="/history/:sessionId" element={<History />} />
//           <Route path="/stats" element={session ? <Stats /> : <Settings defaultColor={borderColor} onColorChange={handleColorChange} />} />
//           <Route path="/stats/:sessionId" element={<Stats />} />
//           <Route path="/settings" element={<Settings defaultColor={borderColor} onColorChange={handleColorChange} />} />
//           <Route path="/previous-sessions" element={<Sessions />} />
//           <Route path="/spins/:sessionId" element={<Spins />} />
//           <Route path="/upload-spins" element={<UploadSpins />} />
//           <Route path="/end-session" element={<EndSession onEndSession={handleEndSession} />} />
//           <Route path="/session/:sessionId" element={<ViewPreviousSession />} />
//         </Routes>
//       </div>
//     </Router>
//   );
// };

// export default App;


import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink } from 'react-router-dom';
import { useSession } from './context/SessionContext';

import Home from './pages/Home';
import Spin from './pages/Spin';
import History from './pages/History';
import Stats from './pages/Stats';
import Settings from './pages/Settings';
import Sessions from './pages/Sessions';
import Spins from './pages/Spins';
import UploadSpins from './pages/UploadSpins';
import EndSession from './components/EndSession';
import ViewPreviousSession from './components/ViewPreviousSession';
import PatternHistory from './components/PatternHistory';
import './App.css';

const App = () => {
  const { session } = useSession();
  const [showPatternHistory, setShowPatternHistory] = useState(false);
  const [hasPatterns, setHasPatterns] = useState(false);
  const [borderColor, setBorderColor] = useState(() => 
    localStorage.getItem('borderColor') || '#061AFB'
  ); // Default color

  // Improved checkForPatterns function with detailed logging
  const checkForPatterns = () => {
    try {
      console.log("Checking for patterns...");
      const patternData = localStorage.getItem('patternHistory');
      console.log("Pattern data from localStorage:", patternData);
      
      if (!patternData) {
        console.log("No pattern data found in localStorage");
        setHasPatterns(false);
        return;
      }
      
      const patterns = JSON.parse(patternData);
      console.log("Parsed patterns:", patterns);
      
      // Verify it's an array with entries
      if (!Array.isArray(patterns)) {
        console.log("Patterns is not an array:", typeof patterns);
        setHasPatterns(false);
        return;
      }
      
      if (patterns.length === 0) {
        console.log("Patterns array is empty");
        setHasPatterns(false);
        return;
      }
      
      // Check if we have valid patterns
      const hasValidPatterns = patterns.some(pattern => 
        pattern && pattern.pattern && pattern.patternType && pattern.timestamp
      );
      
      console.log("Has valid patterns:", hasValidPatterns);
      setHasPatterns(hasValidPatterns);
    } catch (error) {
      console.error("Error checking patterns:", error);
      setHasPatterns(false);
    }
  };
  
  // Check for patterns when app loads
  useEffect(() => {
    checkForPatterns();
    
    // Set up event listeners for pattern changes
    const handlePatternUpdate = () => {
      console.log("Pattern update event detected");
      checkForPatterns();
    };
    
    window.addEventListener('storage', handlePatternUpdate);
    window.addEventListener('patternHistoryUpdated', handlePatternUpdate);
    
    // Set up border color from localStorage
    const savedColor = localStorage.getItem('borderColor');
    if (savedColor) {
      setBorderColor(savedColor);
      document.documentElement.style.setProperty('--border-color', savedColor);
    }
    
    return () => {
      window.removeEventListener('storage', handlePatternUpdate);
      window.removeEventListener('patternHistoryUpdated', handlePatternUpdate);
    };
  }, []);
  
  // Handle session ending
  useEffect(() => {
    const handleSessionEnd = () => {
      console.log("Session ended event detected");
      setHasPatterns(false);
    };
    
    window.addEventListener('sessionEnded', handleSessionEnd);
    
    return () => {
      window.removeEventListener('sessionEnded', handleSessionEnd);
    };
  }, []);
  
  // Function to handle color change
  const handleColorChange = (color) => {
    setBorderColor(color);
    localStorage.setItem('borderColor', color);
    document.documentElement.style.setProperty('--border-color', color);
  };

  return (
    <Router>
      {hasPatterns && (
        <div className="pattern-history-icon" onClick={() => setShowPatternHistory(true)}>
          <i className="fas fa-clipboard"></i>
        </div>
      )}
      <PatternHistory 
        isOpen={showPatternHistory}
        onClose={() => setShowPatternHistory(false)}
      />
      <div className="app-container">
        <nav className="bottom-nav">
          <ul>
            <li>
              <NavLink to="/" className={({ isActive }) => (isActive ? 'active' : '')}>
                <i className="fas fa-home"></i>
              </NavLink>
            </li>
            <li>
              <NavLink to="/spin" className={({ isActive }) => (isActive ? 'active' : '')}>
                <i className="fas fa-compact-disc"></i>
              </NavLink>
            </li>
            <li>
              <NavLink to="/stats" className={({ isActive }) => (isActive ? 'active' : '')}>
                <i className="fas fa-chart-bar"></i>
              </NavLink>
            </li>
            <li>
              <NavLink to="/settings" className={({ isActive }) => (isActive ? 'active' : '')}>
                <i className="fas fa-cog"></i>
              </NavLink>
            </li>
          </ul>
        </nav>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/spin" element={session ? <Spin /> : <Settings defaultColor={borderColor} onColorChange={handleColorChange} />} />
          <Route path="/history" element={session ? <History /> : <Settings defaultColor={borderColor} onColorChange={handleColorChange} />} />
          <Route path="/history/:sessionId" element={<History />} />
          <Route path="/stats" element={session ? <Stats /> : <Settings defaultColor={borderColor} onColorChange={handleColorChange} />} />
          <Route path="/stats/:sessionId" element={<Stats />} />
          <Route path="/settings" element={<Settings defaultColor={borderColor} onColorChange={handleColorChange} />} />
          <Route path="/previous-sessions" element={<Sessions />} />
          <Route path="/spins/:sessionId" element={<Spins />} />
          <Route path="/upload-spins" element={<UploadSpins />} />
          <Route path="/end-session" element={<EndSession />} />
          <Route path="/session/:sessionId" element={<ViewPreviousSession />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;