// import React, { useState } from 'react';
// import styles from './PatternHistory.module.css';

// const PatternMatchModal = ({ patterns: initialPatterns, onClose }) => {
//   const [patterns, setPatterns] = useState(initialPatterns);

//   const handleClose = () => {
//     // Clean up pendingPatterns when closing
//     localStorage.removeItem('pendingPatterns');
//     onClose();
//   };

//   const handleRemovePattern = (type) => {
//     // Get existing patterns from localStorage
//     const existingPatterns = JSON.parse(localStorage.getItem('patternHistory') || '[]');
//     const pattern = patterns[type];
    
//     // Filter out the pattern with matching type and content
//     const updatedPatterns = existingPatterns.filter(existing => 
//       !(existing.patternType === pattern.patternType && 
//         existing.pattern === pattern.pattern)
//     );
    
//     // Save back to localStorage
//     // localStorage.setItem('patternHistory', JSON.stringify(updatedPatterns));
//     localStorage.setItem('patternHistory', JSON.stringify(updatedPatterns));
// window.dispatchEvent(new Event('patternHistoryUpdated'));
// localStorage.setItem('pendingPatterns', JSON.stringify(patternsWithMeta));
    
//     // Remove this pattern from pendingPatterns
//     const pendingPatterns = JSON.parse(localStorage.getItem('pendingPatterns') || '{}');
//     const updatedPendingPatterns = { ...pendingPatterns };
//     delete updatedPendingPatterns[type];
//     localStorage.setItem('pendingPatterns', JSON.stringify(updatedPendingPatterns));
  
//     // Update local state
//     const updatedModalPatterns = { ...patterns };
//     delete updatedModalPatterns[type];
//     setPatterns(updatedModalPatterns);
    
//     // Trigger UI updates
//     window.dispatchEvent(new Event('patternHistoryUpdated'));
//     window.dispatchEvent(new Event('storage'));
    
//     // Only close modal if no patterns remain
//     if (Object.keys(updatedModalPatterns).length === 0) {
//       handleClose(); // Use handleClose instead of onClose
//     }
//   };

//   const getPatternTypeLabel = (type) => {
//     switch (type) {
//       case 'redBlack': return 'Red/Black';
//       case 'oddEven': return 'Odd/Even';
//       case 'highLow': return 'High/Low';
//       case 'dozen': return 'Dozens';
//       case 'column': return 'Columns';
//       default: return type;
//     }
//   };

//   const getChipClass = (symbol, type) => {
//     switch (type) {
//       case 'redBlack':
//         return symbol === 'R' ? styles.red :
//           symbol === 'B' ? styles.black :
//             styles.green;
//       case 'oddEven':
//         return symbol === 'O' ? styles.red :
//           symbol === 'E' ? styles.black :
//             styles.green;
//       case 'highLow':
//         return symbol === 'H' ? styles.red :
//           symbol === 'L' ? styles.black :
//             styles.green;
//       case 'dozen':
//         return symbol === '1' ? styles.dozen1 :
//           symbol === '2' ? styles.dozen2 :
//             symbol === '3' ? styles.dozen3 :
//               styles.green;
//       case 'column':
//         return symbol === '1' ? styles.column1 :
//           symbol === '2' ? styles.column2 :
//             symbol === '3' ? styles.column3 :
//               styles.green;
//       default:
//         return styles.black;
//     }
//   };

//   return (
//     <div className={styles.modalOverlay}>
//       <div className={styles.modalContent}>
//         <div className={styles.modalHeader}>
//           <h2>Pattern Match Found!</h2>
//           <button className={styles.closeButton} onClick={handleClose}>×</button>
//         </div>
        
//         <div className={styles.patternsContainer}>
//           {Object.entries(patterns).map(([type, patternMatch]) => (
//             <div key={type} className={styles.patternCard}>
//               <div className={styles.patternHeader}>
//                 <h3>{getPatternTypeLabel(patternMatch.patternType)}</h3>
//                 <button
//                   className={styles.removeButton}
//                   onClick={() => handleRemovePattern(type)}
//                 >
//                   Remove
//                 </button>
//               </div>

//               <div className={styles.patternSequence}>
//                 <div className={styles.sequenceLabel}>Pattern:</div>
//                 <div className={styles.chips}>
//                   {patternMatch.pattern.split('').map((symbol, i) => (
//                     <span
//                       key={i}
//                       className={`${styles.chip} ${getChipClass(symbol, patternMatch.patternType)}`}
//                     >
//                       {symbol}
//                     </span>
//                   ))}
//                 </div>
//               </div>

//               <div className={styles.patternSequence}>
//                 <div className={styles.sequenceLabel}>Next Bets:</div>
//                 <div className={styles.chips}>
//                   {patternMatch.nextSixAfter.split('').slice(0, 8).map((symbol, i) => (
//                     <span
//                       key={i}
//                       className={`${styles.chip} ${getChipClass(symbol, patternMatch.patternType)}`}
//                     >
//                       {symbol}
//                     </span>
//                   ))}
//                 </div>
//               </div>

//               <div className={styles.patternInfo}>
//                 <div>Previous End Position: Spin #{patternMatch.previousOccurrence + 1}</div>
//                 <div>Total Occurrences: {patternMatch.totalOccurrences}</div>
//                 <div>Found at: {new Date(patternMatch.timestamp).toLocaleString()}</div>
//                 <div>Pattern Matched: Spin #{patternMatch.matchedAtSpin}</div>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default PatternMatchModal;


import React, { useState } from 'react';
import styles from './PatternHistory.module.css';

const PatternMatchModal = ({ patterns: initialPatterns, onClose }) => {
  const [patterns, setPatterns] = useState(initialPatterns);

  const handleClose = () => {
    // Clean up pendingPatterns when closing
    localStorage.removeItem('pendingPatterns');
    onClose();
  };

  const handleRemovePattern = (type) => {
    // Get existing patterns from localStorage
    const existingPatterns = JSON.parse(localStorage.getItem('patternHistory') || '[]');
    const pattern = patterns[type];
    
    // Filter out the pattern with matching type and content
    const updatedPatterns = existingPatterns.filter(existing => 
      !(existing.patternType === pattern.patternType && 
        existing.pattern === pattern.pattern)
    );
    
    // Save back to localStorage
    localStorage.setItem('patternHistory', JSON.stringify(updatedPatterns));
    
    // Remove this pattern from pendingPatterns
    const pendingPatterns = JSON.parse(localStorage.getItem('pendingPatterns') || '{}');
    const updatedPendingPatterns = { ...pendingPatterns };
    delete updatedPendingPatterns[type];
    localStorage.setItem('pendingPatterns', JSON.stringify(updatedPendingPatterns));
  
    // Update local state
    const updatedModalPatterns = { ...patterns };
    delete updatedModalPatterns[type];
    setPatterns(updatedModalPatterns);
    
    // Trigger UI updates
    window.dispatchEvent(new Event('patternHistoryUpdated'));
    window.dispatchEvent(new Event('storage'));
    
    // Only close modal if no patterns remain
    if (Object.keys(updatedModalPatterns).length === 0) {
      handleClose(); // Use handleClose instead of onClose
    }
  };

  const getPatternTypeLabel = (type) => {
    switch (type) {
      case 'redBlack': return 'Red/Black';
      case 'oddEven': return 'Odd/Even';
      case 'highLow': return 'High/Low';
      case 'dozen': return 'Dozens';
      case 'column': return 'Columns';
      default: return type;
    }
  };

  const getChipClass = (symbol, type) => {
    switch (type) {
      case 'redBlack':
        return symbol === 'R' ? styles.red :
          symbol === 'B' ? styles.black :
            styles.green;
      case 'oddEven':
        return symbol === 'O' ? styles.red :
          symbol === 'E' ? styles.black :
            styles.green;
      case 'highLow':
        return symbol === 'H' ? styles.red :
          symbol === 'L' ? styles.black :
            styles.green;
      case 'dozen':
        return symbol === '1' ? styles.dozen1 :
          symbol === '2' ? styles.dozen2 :
            symbol === '3' ? styles.dozen3 :
              styles.green;
      case 'column':
        return symbol === '1' ? styles.column1 :
          symbol === '2' ? styles.column2 :
            symbol === '3' ? styles.column3 :
              styles.green;
      default:
        return styles.black;
    }
  };

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <div className={styles.modalHeader}>
          <h2>Pattern Match Found!</h2>
          <button className={styles.closeButton} onClick={handleClose}>×</button>
        </div>
        
        <div className={styles.patternsContainer}>
          {Object.entries(patterns).map(([type, patternMatch]) => (
            <div key={type} className={styles.patternCard}>
              <div className={styles.patternHeader}>
                <h3>{getPatternTypeLabel(patternMatch.patternType)}</h3>
                <button
                  className={styles.removeButton}
                  onClick={() => handleRemovePattern(type)}
                >
                  Remove
                </button>
              </div>

              <div className={styles.patternSequence}>
                <div className={styles.sequenceLabel}>Pattern:</div>
                <div className={styles.chips}>
                  {patternMatch.pattern.split('').map((symbol, i) => (
                    <span
                      key={i}
                      className={`${styles.chip} ${getChipClass(symbol, patternMatch.patternType)}`}
                    >
                      {symbol}
                    </span>
                  ))}
                </div>
              </div>

              <div className={styles.patternSequence}>
                <div className={styles.sequenceLabel}>Next Bets:</div>
                <div className={styles.chips}>
                  {patternMatch.nextSixAfter.split('').slice(0, 8).map((symbol, i) => (
                    <span
                      key={i}
                      className={`${styles.chip} ${getChipClass(symbol, patternMatch.patternType)}`}
                    >
                      {symbol}
                    </span>
                  ))}
                </div>
              </div>

              <div className={styles.patternInfo}>
                <div>Previous End Position: Spin #{patternMatch.previousOccurrence + 1}</div>
                <div>Total Occurrences: {patternMatch.totalOccurrences}</div>
                <div>Found at: {new Date(patternMatch.timestamp).toLocaleString()}</div>
                <div>Pattern Matched: Spin #{patternMatch.matchedAtSpin}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PatternMatchModal;