import React, { useState, useEffect } from 'react';
import styles from './PatternHistory.module.css';

const PatternHistory = ({ isOpen, onClose }) => {
  const [patterns, setPatterns] = useState([]);

  useEffect(() => {
    if (isOpen) {
      const savedPatterns = JSON.parse(localStorage.getItem('patternHistory') || '[]');

      // Remove duplicates based on pattern and patternType
      const uniquePatterns = savedPatterns.reduce((acc, current) => {
        const isDuplicate = acc.find(pattern =>
          pattern.pattern === current.pattern &&
          pattern.patternType === current.patternType
        );
        if (!isDuplicate) {
          acc.push(current);
        }
        return acc;
      }, []);

      // Sort patterns by timestamp in descending order (most recent first)
      const sortedPatterns = uniquePatterns.sort((a, b) => b.timestamp - a.timestamp);
      setPatterns(sortedPatterns);
    }
  }, [isOpen]);

  const handleRemovePattern = (index) => {
    const updatedPatterns = patterns.filter((_, i) => i !== index);
    setPatterns(updatedPatterns);
    localStorage.setItem('patternHistory', JSON.stringify(updatedPatterns));
  
    // Trigger storage event to update icon visibility
    window.dispatchEvent(new Event('storage'));
    window.dispatchEvent(new Event('patternHistoryUpdated'));
  
    // Close if all patterns are removed
    if (updatedPatterns.length === 0) {
      onClose();
    }
  };
  
  const getPatternTypeLabel = (type) => {
    switch (type) {
      case 'redBlack': return 'Red/Black';
      case 'oddEven': return 'Odd/Even';
      case 'highLow': return 'High/Low';
      case 'dozen': return 'Dozens';
      case 'column': return 'Columns';
      default: return type;
    }
  };

  const getChipClass = (symbol, type) => {
    switch (type) {
      case 'redBlack':
        return symbol === 'R' ? styles.red :
          symbol === 'B' ? styles.black :
            styles.green;
      case 'oddEven':
        return symbol === 'O' ? styles.red :
          symbol === 'E' ? styles.black :
            styles.green;
      case 'highLow':
        return symbol === 'H' ? styles.red :
          symbol === 'L' ? styles.black :
            styles.green;
      case 'dozen':
        return symbol === '1' ? styles.dozen1 :
          symbol === '2' ? styles.dozen2 :
            symbol === '3' ? styles.dozen3 :
              styles.green;
      case 'column':
        return symbol === '1' ? styles.column1 :
          symbol === '2' ? styles.column2 :
            symbol === '3' ? styles.column3 :
              styles.green;
      default:
        return styles.black;
    }
  };

  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <div className={styles.modalHeader}>
          <h2>Pattern History</h2>
          <button className={styles.closeButton} onClick={onClose}>×</button>
        </div>

        <div className={styles.patternsContainer}>
          {patterns.length === 0 ? (
            <div className={styles.noPatterns}>No patterns found yet</div>
          ) : (
            patterns.map((patternMatch, index) => (
              <div key={index} className={styles.patternCard}>
                <div className={styles.patternHeader}>
                  <h3>{getPatternTypeLabel(patternMatch.patternType)}</h3>
                  <button
                    className={styles.removeButton}
                    onClick={() => handleRemovePattern(index)}
                  >
                    Remove
                  </button>
                </div>

                <div className={styles.patternSequence}>
                  <div className={styles.sequenceLabel}>Pattern:</div>
                  <div className={styles.chips}>
                    {patternMatch.pattern.split('').map((symbol, i) => (
                      <span
                        key={i}
                        className={`${styles.chip} ${getChipClass(symbol, patternMatch.patternType)}`}
                      >
                        {symbol}
                      </span>
                    ))}
                  </div>
                </div>

                <div className={styles.patternSequence}>
                  <div className={styles.sequenceLabel}>Next Bets:</div>
                  <div className={styles.chips}>
                    {patternMatch.nextSixAfter.split('').map((symbol, i) => (
                      <span
                        key={i}
                        className={`${styles.chip} ${getChipClass(symbol, patternMatch.patternType)}`}
                      >
                        {symbol}
                      </span>
                    ))}
                  </div>
                </div>

                <div className={styles.patternInfo}>
                  <div>Previous End Position: Spin #{patternMatch.previousOccurrence + 1}</div>
                  <div>Total Occurrences: {patternMatch.totalOccurrences}</div>
                  <div>Found at: {new Date(patternMatch.timestamp).toLocaleString()}</div>
                  <div>Pattern Matched: Spin #{patternMatch.matchedAtSpin}</div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default PatternHistory;