import React, { useState, useEffect } from 'react';
import styles from './AnalysisCriteria.module.css';

const AnalysisCriteria = ({ onClose }) => {
  // 50/50 bets criteria (Red/Black, Odd/Even, High/Low)
  const [evenOddsLookback, setEvenOddsLookback] = useState(5);
  const [evenOddsHighlight, setEvenOddsHighlight] = useState(49);
  const [evenOddsAboveBelow, setEvenOddsAboveBelow] = useState('Above');

  // Thirds bets criteria (Dozens, Columns)
  const [thirdsLookback, setThirdsLookback] = useState(5);
  const [thirdsHighlight, setThirdsHighlight] = useState(49);
  const [thirdsAboveBelow, setThirdsAboveBelow] = useState('Above');

  // Rep and Neighbors settings
  const [repeatCount, setRepeatCount] = useState(14);
  const [neighborsCount, setNeighborsCount] = useState(6);

  useEffect(() => {
    const savedCriteria = JSON.parse(localStorage.getItem('analysisCriteria') || '{}');
    
    // Load 50/50 settings
    setEvenOddsLookback(savedCriteria.evenOddsLookback || 5);
    setEvenOddsHighlight(savedCriteria.evenOddsHighlight ? savedCriteria.evenOddsHighlight * 100 : 49);
    setEvenOddsAboveBelow(savedCriteria.evenOddsAboveBelow || 'Above');
    
    // Load thirds settings
    setThirdsLookback(savedCriteria.thirdsLookback || 5);
    setThirdsHighlight(savedCriteria.thirdsHighlight ? savedCriteria.thirdsHighlight * 100 : 49);
    setThirdsAboveBelow(savedCriteria.thirdsAboveBelow || 'Above');
    
    // Load rep and neighbors settings
    setRepeatCount(savedCriteria.repeatCount || 14);
    setNeighborsCount(savedCriteria.neighborsCount || 6);
    
    // For backwards compatibility
    if (savedCriteria.spinsToLookBack && !savedCriteria.evenOddsLookback) {
      setEvenOddsLookback(savedCriteria.spinsToLookBack);
      setThirdsLookback(savedCriteria.spinsToLookBack);
    }
    
    if (savedCriteria.startHighlightingAt && !savedCriteria.evenOddsHighlight) {
      setEvenOddsHighlight(savedCriteria.startHighlightingAt * 100);
      setThirdsHighlight(savedCriteria.startHighlightingAt * 100);
    }
    
    if (savedCriteria.aboveOrBelow && !savedCriteria.evenOddsAboveBelow) {
      setEvenOddsAboveBelow(savedCriteria.aboveOrBelow);
      setThirdsAboveBelow(savedCriteria.aboveOrBelow);
    }
  }, []);

  const handleSave = () => {
    const criteria = {
      // 50/50 bets settings
      evenOddsLookback: parseInt(evenOddsLookback) || 5,
      evenOddsHighlight: evenOddsHighlight / 100,
      evenOddsAboveBelow,
      
      // Thirds bets settings
      thirdsLookback: parseInt(thirdsLookback) || 5,
      thirdsHighlight: thirdsHighlight / 100,
      thirdsAboveBelow,
      
      // Rep and Neighbors settings
      repeatCount: parseInt(repeatCount) || 14,
      neighborsCount: parseInt(neighborsCount) || 6,
      
      // For backwards compatibility
      spinsToLookBack: parseInt(evenOddsLookback) || 5,
      startHighlightingAt: evenOddsHighlight / 100,
      aboveOrBelow: evenOddsAboveBelow,
    };
    
    localStorage.setItem('analysisCriteria', JSON.stringify(criteria));
    localStorage.setItem('neighborCount', parseInt(neighborsCount) || 6); // For backwards compatibility
    onClose();
  };

  // Function to handle incrementing and decrementing values
  const handleIncrement = (setter, value, min = 1, max = 100) => {
    const newValue = parseInt(value) + 1;
    if (newValue <= max) {
      setter(newValue);
    }
  };

  const handleDecrement = (setter, value, min = 1, max = 100) => {
    const newValue = parseInt(value) - 1;
    if (newValue >= min) {
      setter(newValue);
    }
  };

  // Validator function for direct input
  const handleInputChange = (setter, value, min = 1, max = 100) => {
    const numValue = parseInt(value);
    if (!isNaN(numValue)) {
      if (numValue < min) setter(min);
      else if (numValue > max) setter(max);
      else setter(numValue);
    } else {
      setter(value); // Allow temporary invalid input for better UX
    }
  };

  return (
    <>
      <div className={styles.modalBackdrop} onClick={onClose}></div>
      <div className={styles.modal}>
        <h2 className={styles.header}>Analysis Criteria</h2>
        
        {/* 50/50 Bets Section (Red/Black, Odd/Even, High/Low) */}
        <div className={styles.section}>
          <h3 className={styles.sectionHeader}>Red/Black, Odd/Even, High/Low</h3>
          
          <div className={styles.inputGroup}>
            <label className={styles.inputLabel}>Spins to look back:</label>
            <div className={styles.numberSelector}>
              <button 
                className={styles.selectorButton}
                onClick={() => handleDecrement(setEvenOddsLookback, evenOddsLookback, 1, 50)}
              >−</button>
              <input
                type="number"
                value={evenOddsLookback}
                onChange={(e) => handleInputChange(setEvenOddsLookback, e.target.value, 1, 50)}
                className={styles.numberInput}
                min="1"
                max="50"
              />
              <button 
                className={styles.selectorButton}
                onClick={() => handleIncrement(setEvenOddsLookback, evenOddsLookback, 1, 50)}
              >+</button>
            </div>
          </div>
          
          <div className={styles.inputGroup}>
            <label className={styles.inputLabel}>Start highlighting at:</label>
            <div className={styles.numberSelector}>
              <button 
                className={styles.selectorButton}
                onClick={() => handleDecrement(setEvenOddsHighlight, evenOddsHighlight, 1, 100)}
              >−</button>
              <input
                type="number"
                value={evenOddsHighlight}
                onChange={(e) => handleInputChange(setEvenOddsHighlight, e.target.value, 1, 100)}
                className={styles.numberInput}
                min="1"
                max="100"
              />
              <button 
                className={styles.selectorButton}
                onClick={() => handleIncrement(setEvenOddsHighlight, evenOddsHighlight, 1, 100)}
              >+</button>
            </div>
            <span className={styles.percentSign}>%</span>
          </div>
          
          <div className={styles.radioGroup}>
            <label className={styles.radioLabel}>
              <input
                type="radio"
                value="Above"
                checked={evenOddsAboveBelow === 'Above'}
                onChange={(e) => setEvenOddsAboveBelow(e.target.value)}
                className={styles.radioInput}
              />
              Above
            </label>
            <label className={styles.radioLabel}>
              <input
                type="radio"
                value="Below"
                checked={evenOddsAboveBelow === 'Below'}
                onChange={(e) => setEvenOddsAboveBelow(e.target.value)}
                className={styles.radioInput}
              />
              Below
            </label>
          </div>
        </div>
        
        {/* Thirds Bets Section (Dozens, Columns) */}
        <div className={styles.section}>
          <h3 className={styles.sectionHeader}>Dozens & Columns</h3>
          
          <div className={styles.inputGroup}>
            <label className={styles.inputLabel}>Spins to look back:</label>
            <div className={styles.numberSelector}>
              <button 
                className={styles.selectorButton}
                onClick={() => handleDecrement(setThirdsLookback, thirdsLookback, 1, 50)}
              >−</button>
              <input
                type="number"
                value={thirdsLookback}
                onChange={(e) => handleInputChange(setThirdsLookback, e.target.value, 1, 50)}
                className={styles.numberInput}
                min="1"
                max="50"
              />
              <button 
                className={styles.selectorButton}
                onClick={() => handleIncrement(setThirdsLookback, thirdsLookback, 1, 50)}
              >+</button>
            </div>
          </div>
          
          <div className={styles.inputGroup}>
            <label className={styles.inputLabel}>Start highlighting at:</label>
            <div className={styles.numberSelector}>
              <button 
                className={styles.selectorButton}
                onClick={() => handleDecrement(setThirdsHighlight, thirdsHighlight, 1, 100)}
              >−</button>
              <input
                type="number"
                value={thirdsHighlight}
                onChange={(e) => handleInputChange(setThirdsHighlight, e.target.value, 1, 100)}
                className={styles.numberInput}
                min="1"
                max="100"
              />
              <button 
                className={styles.selectorButton}
                onClick={() => handleIncrement(setThirdsHighlight, thirdsHighlight, 1, 100)}
              >+</button>
            </div>
            <span className={styles.percentSign}>%</span>
          </div>
          
          <div className={styles.radioGroup}>
            <label className={styles.radioLabel}>
              <input
                type="radio"
                value="Above"
                checked={thirdsAboveBelow === 'Above'}
                onChange={(e) => setThirdsAboveBelow(e.target.value)}
                className={styles.radioInput}
              />
              Above
            </label>
            <label className={styles.radioLabel}>
              <input
                type="radio"
                value="Below"
                checked={thirdsAboveBelow === 'Below'}
                onChange={(e) => setThirdsAboveBelow(e.target.value)}
                className={styles.radioInput}
              />
              Below
            </label>
          </div>
        </div>
        
        {/* Other Settings Section */}
        <div className={styles.section}>
          <h3 className={styles.sectionHeader}>Other Settings</h3>
          
          <div className={styles.inputGroup}>
            <label className={styles.inputLabel}>Repeat Count:</label>
            <div className={styles.numberSelector}>
              <button 
                className={styles.selectorButton}
                onClick={() => handleDecrement(setRepeatCount, repeatCount, 1, 50)}
              >−</button>
              <input
                type="number"
                value={repeatCount}
                onChange={(e) => handleInputChange(setRepeatCount, e.target.value, 1, 50)}
                className={styles.numberInput}
                min="1"
                max="50"
              />
              <button 
                className={styles.selectorButton}
                onClick={() => handleIncrement(setRepeatCount, repeatCount, 1, 50)}
              >+</button>
            </div>
          </div>

          <div className={styles.inputGroup}>
            <label className={styles.inputLabel}>Neighbors Count:</label>
            <div className={styles.numberSelector}>
              <button 
                className={styles.selectorButton}
                onClick={() => handleDecrement(setNeighborsCount, neighborsCount, 1, 19)}
              >−</button>
              <input
                type="number"
                value={neighborsCount}
                onChange={(e) => handleInputChange(setNeighborsCount, e.target.value, 1, 19)}
                className={styles.numberInput}
                min="1"
                max="19"
              />
              <button 
                className={styles.selectorButton}
                onClick={() => handleIncrement(setNeighborsCount, neighborsCount, 1, 19)}
              >+</button>
            </div>
          </div>
        </div>

        <div className={styles.buttonContainer}>
          <button className={`${styles.button} ${styles.cancelButton}`} onClick={onClose}>Cancel</button>
          <button className={`${styles.button} ${styles.saveButton}`} onClick={handleSave}>Save</button>
        </div>
      </div>
    </>
  );
};

export default AnalysisCriteria;