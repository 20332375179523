import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSession } from "../context/SessionContext";
import EnterPreviousSpinsModal from "../components/EnterPreviousSpinsModal";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./Settings.css";
import AnalysisCriteria from '../components/AnalysisCriteria';
import { useSpin } from '../context/SpinContext';
import ColorPicker from '../components/ColorPicker';
import MobileSelect from '../components/MobileSelect';

const tabOptions = [
  { value: 'RedBlack', label: 'R/B' },
  { value: 'OddEven', label: 'O/E' },
  { value: 'HighLow', label: 'H/L' },
  { value: 'Dozens', label: 'Doz' },
  { value: 'Columns', label: 'Col' },
  { value: 'Rep', label: 'Rep' },
  { value: 'Neb', label: 'Neb' }
];

const Settings = ({ defaultColor, onColorChange }) => {
  const [sessionDetails, setSessionDetails] = useState({
    name: "",
    location: "",
  });
  const [notes, setNotes] = useState("");
  const [previousSpins, setPreviousSpins] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showAnalysisCriteria, setShowAnalysisCriteria] = useState(false);
  const [defaultScreen, setDefaultScreen] = useState('RedBlack');
  const [showCountBadge, setShowCountBadge] = useState(() => {
    return localStorage.getItem('showCountBadge') === 'true';
  });

  // Add this new state
const [streakCount, setStreakCount] = useState(() => {
  return parseInt(localStorage.getItem('patternStreakCount')) || 5;
});

  const navigate = useNavigate();
  const location = useLocation();
  const { session, startSession, endSession } = useSession();
  const { fetchSpins, resetSpins } = useSpin();

  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    if (location.state?.showCriteriaModal) {
      setShowAnalysisCriteria(true);
    }
    // Load the default screen from local storage
    const savedDefaultScreen = localStorage.getItem('defaultScreen');
    if (savedDefaultScreen) {
      setDefaultScreen(savedDefaultScreen);
    }
  }, [location.state]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSessionDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
  };

  const handleDefaultScreenChange = (e) => {
    const selectedScreen = e.target.value;
    setDefaultScreen(selectedScreen);
    localStorage.setItem('defaultScreen', selectedScreen);
  };

  const handleCountBadgeChange = (e) => {
    const checked = e.target.checked;
    setShowCountBadge(checked);
    localStorage.setItem('showCountBadge', checked);
  };

  const handleStartSession = async () => {
    if (!sessionDetails.name) {
      toast.error("Name is required");
      return;
    }
    try {
      const response = await fetch(`${backendUrl}/api/sessions`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...sessionDetails, notes }),
      });
      if (response.ok) {
        const data = await response.json();
        
        // Reset pattern detection settings to defaults
        localStorage.setItem('evenOddsStreakCount', '5');
        localStorage.setItem('thirdsStreakCount', '5');
        localStorage.setItem('enableEvenOddsPatterns', 'true');
        localStorage.setItem('enableThirdsPatterns', 'true');
        localStorage.removeItem('patternHistory');
        
        // Start the session
        startSession(data);
        toast.success("Session started successfully");
        navigate("/settings");
      } else {
        toast.error("Failed to start session");
      }
    } catch (error) {
      toast.error("An error occurred while starting the session");
    }
  };


  const handleEndSession = async () => {
    if (!session) {
      toast.error("No active session to end");
      return;
    }
    try {
      // Will properly save pattern history
      await endSession(notes);
      resetSpins();
      setSessionDetails({ name: "", location: "" });
      setNotes("");
      toast.success("Session ended successfully");
    } catch (error) {
      toast.error("An error occurred while ending the session");
    }
  };
  
  const handleAddSpin = (spinValue) => {
    setPreviousSpins([
      ...previousSpins,
      { spin_value: spinValue, outcome: "NB", bet_amt: 0 },
    ]);
  };

  const handleSavePreviousSpins = async () => {
    if (previousSpins.length === 0) return;

    try {
      const response = await fetch(`${backendUrl}/api/spins/bulk`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ session_id: session._id, spins: previousSpins }),
      });

      if (response.ok) {
        setPreviousSpins([]);
        setShowModal(false);
        toast.success("Previous spins saved successfully");
        await fetchSpins(session._id);
        navigate("/settings");
      } else {
        const errorData = await response.json();
        toast.error(`Failed to save previous spins: ${errorData.error}`);
      }
    } catch (error) {
      console.error("Failed to save previous spins:", error);
      toast.error("Failed to save previous spins. Please try again later.");
    }
  };

  const handleEnterPreviousSpins = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className="settings-container">
      <ToastContainer position="top-right" autoClose={3000} />
      <h1>Settings</h1>
      {!session ? (
        <div>
          <h2>Start New Session</h2>
          <form className="session-form">
            <div className="form-group">
              <label>Name: </label>
              <input
                type="text"
                name="name"
                value={sessionDetails.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Location: </label>
              <input
                type="text"
                name="location"
                value={sessionDetails.location}
                onChange={handleChange}
              />
            </div>
            
            <div className="form-group">
              <label>Notes: </label>
              <textarea
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                className="notes-textarea"
              ></textarea>
            </div>
            <button
              type="button"
              onClick={handleStartSession}
              className="btn-primary green"
            >
              Start Session
            </button>
          </form>
        </div>
      ) : (
        <div>
          <div>
            <button
              type="button"
              onClick={handleEnterPreviousSpins}
              className="btn-primary"
            >
              Enter Previous Spins
            </button>
          </div>
          <div>
            <button
              type="button"
              onClick={() => setShowAnalysisCriteria(true)}
              className="btn-primary purple"
            >
              Analysis Criteria
            </button>
            {showAnalysisCriteria && (
              <AnalysisCriteria onClose={() => setShowAnalysisCriteria(false)} />
            )}
          </div>
          <div>
            <button
              type="button"
              onClick={handleEndSession}
              className="btn-primary red"
            >
              End Session
            </button>
          </div>
          <div>
            <button
              type="button"
              onClick={() => navigate('/upload-spins')}
              className="btn-primary"
            >
              Upload Spins
            </button>
          </div>
          <div className="form-group">
            <label>Default Tab Screen:</label>
            <MobileSelect
              value={defaultScreen}
              onChange={(value) => {
                setDefaultScreen(value);
                localStorage.setItem('defaultScreen', value);
              }}
              options={tabOptions}
            />
          </div>
          <div className="settings-option">
            <input
              type="checkbox"
              id="showCountBadge"
              checked={showCountBadge}
              onChange={handleCountBadgeChange}
            />
            <label htmlFor="showCountBadge">
              Show count badge on Rep Page
            </label>
          </div>

          <PatternDetectionSection 
  streakCount={streakCount}
  setStreakCount={setStreakCount}
/>

          <div className="color-picker">
            <ColorPicker defaultColor={defaultColor} onColorChange={onColorChange} />
          </div>
        </div>
      )}

      {/* Modal for entering previous spins */}
      <EnterPreviousSpinsModal
        show={showModal}
        handleClose={handleCloseModal}
        handleAddSpin={handleAddSpin}
        previousSpins={previousSpins}
        handleSave={handleSavePreviousSpins}
      />
    </div>
  );
};

const PatternDetectionSection = () => {
  // Settings for 50/50 bets (Red/Black, Odd/Even, High/Low)
  const [evenOddsStreakCount, setEvenOddsStreakCount] = useState(() => {
    return parseInt(localStorage.getItem('evenOddsStreakCount')) || 10;
  });
  
  // Settings for thirds bets (Dozens, Columns)
  const [thirdsStreakCount, setThirdsStreakCount] = useState(() => {
    return parseInt(localStorage.getItem('thirdsStreakCount')) || 6;
  });
  
  // Enable/disable toggles
  const [enableEvenOdds, setEnableEvenOdds] = useState(() => {
    const saved = localStorage.getItem('enableEvenOddsPatterns');
    return saved !== null ? saved === 'true' : true; // Default to true
  });
  
  const [enableThirds, setEnableThirds] = useState(() => {
    const saved = localStorage.getItem('enableThirdsPatterns');
    return saved !== null ? saved === 'true' : true; // Default to true
  });
  
  // Error states
  const [evenOddsError, setEvenOddsError] = useState('');
  const [thirdsError, setThirdsError] = useState('');
  
  // Modal states
  const [showEvenOddsSelector, setShowEvenOddsSelector] = useState(false);
  const [showThirdsSelector, setShowThirdsSelector] = useState(false);

  // Handle Even/Odds streak count change
  const handleEvenOddsStreakCountChange = (value) => {
    if (isNaN(value)) {
      setEvenOddsError('Please enter a valid number');
      return;
    }
    
    if (value < 3 || value > 20) {
      setEvenOddsError('Value must be between 3 and 20');
      return;
    }
    
    setEvenOddsError('');
    setEvenOddsStreakCount(value);
    localStorage.setItem('evenOddsStreakCount', value);
  };

  // Handle Thirds streak count change
  const handleThirdsStreakCountChange = (value) => {
    if (isNaN(value)) {
      setThirdsError('Please enter a valid number');
      return;
    }
    
    if (value < 3 || value > 20) {
      setThirdsError('Value must be between 3 and 20');
      return;
    }
    
    setThirdsError('');
    setThirdsStreakCount(value);
    localStorage.setItem('thirdsStreakCount', value);
  };

  // Handle Enable/Disable toggles
  const handleToggleEvenOdds = (e) => {
    const isEnabled = e.target.checked;
    setEnableEvenOdds(isEnabled);
    localStorage.setItem('enableEvenOddsPatterns', isEnabled);
  };

  const handleToggleThirds = (e) => {
    const isEnabled = e.target.checked;
    setEnableThirds(isEnabled);
    localStorage.setItem('enableThirdsPatterns', isEnabled);
  };

  return (
    <div className="pattern-settings-container">
      <h2>Pattern Detection Settings</h2>
      
      {/* Even/Odds (50/50) Settings */}
      <div className="pattern-section">
        <div className="pattern-section-header">
          <div className="pattern-toggle-container">
            <label className="toggle-switch">
              <input 
                type="checkbox" 
                checked={enableEvenOdds} 
                onChange={handleToggleEvenOdds}
              />
              <span className="toggle-slider"></span>
            </label>
            <span className="toggle-label">
              Enable R/B, O/E, H/L Pattern Detection
            </span>
          </div>
        </div>
        
        {enableEvenOdds && (
          <>
            <div className="streak-count-selector">
              <label>Number of consecutive spins to check:</label>
              
              <div className="mobile-number-selector">
                <button 
                  className="selector-button decrease-button"
                  onClick={() => handleEvenOddsStreakCountChange(evenOddsStreakCount - 1)}
                  disabled={evenOddsStreakCount <= 3}
                  aria-label="Decrease count"
                >
                  −
                </button>
                
                <div 
                  className="streak-count-display" 
                  onClick={() => setShowEvenOddsSelector(true)}
                >
                  <span>{evenOddsStreakCount}</span>
                </div>
                
                <button 
                  className="selector-button increase-button"
                  onClick={() => handleEvenOddsStreakCountChange(evenOddsStreakCount + 1)}
                  disabled={evenOddsStreakCount >= 20}
                  aria-label="Increase count"
                >
                  +
                </button>
              </div>
            </div>
            
            {evenOddsError && (
              <div className="pattern-detection-error">
                {evenOddsError}
              </div>
            )}
            
            <div className="pattern-description">
              This setting applies to Red/Black, Odd/Even, and High/Low patterns.
            </div>
          </>
        )}
      </div>
      
      {/* Thirds Settings */}
      <div className="pattern-section">
        <div className="pattern-section-header">
          <div className="pattern-toggle-container">
            <label className="toggle-switch">
              <input 
                type="checkbox" 
                checked={enableThirds} 
                onChange={handleToggleThirds}
              />
              <span className="toggle-slider"></span>
            </label>
            <span className="toggle-label">
              Enable Dozens & Columns Pattern Detection
            </span>
          </div>
        </div>
        
        {enableThirds && (
          <>
            <div className="streak-count-selector">
              <label>Number of consecutive spins to check:</label>
              
              <div className="mobile-number-selector">
                <button 
                  className="selector-button decrease-button"
                  onClick={() => handleThirdsStreakCountChange(thirdsStreakCount - 1)}
                  disabled={thirdsStreakCount <= 3}
                  aria-label="Decrease count"
                >
                  −
                </button>
                
                <div 
                  className="streak-count-display" 
                  onClick={() => setShowThirdsSelector(true)}
                >
                  <span>{thirdsStreakCount}</span>
                </div>
                
                <button 
                  className="selector-button increase-button"
                  onClick={() => handleThirdsStreakCountChange(thirdsStreakCount + 1)}
                  disabled={thirdsStreakCount >= 20}
                  aria-label="Increase count"
                >
                  +
                </button>
              </div>
            </div>
            
            {thirdsError && (
              <div className="pattern-detection-error">
                {thirdsError}
              </div>
            )}
            
            <div className="pattern-description">
              This setting applies to Dozens and Columns patterns.
            </div>
          </>
        )}
      </div>
      
      {/* Modal for Even/Odds selector */}
      {showEvenOddsSelector && (
        <div className="number-selector-modal">
          <div 
            className="modal-overlay" 
            onClick={() => setShowEvenOddsSelector(false)}
          ></div>
          <div className="selector-modal-content">
            <h3>Select Consecutive Spins Count (R/B, O/E, H/L)</h3>
            <div className="selector-grid">
              {[...Array(18)].map((_, i) => (
                <button 
                  key={i + 3} 
                  className={`selector-grid-button ${evenOddsStreakCount === i + 3 ? 'selected' : ''}`}
                  onClick={() => {
                    handleEvenOddsStreakCountChange(i + 3);
                    setShowEvenOddsSelector(false);
                  }}
                >
                  {i + 3}
                </button>
              ))}
            </div>
            <button 
              className="selector-close-button"
              onClick={() => setShowEvenOddsSelector(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}
      
      {/* Modal for Thirds selector */}
      {showThirdsSelector && (
        <div className="number-selector-modal">
          <div 
            className="modal-overlay" 
            onClick={() => setShowThirdsSelector(false)}
          ></div>
          <div className="selector-modal-content">
            <h3>Select Consecutive Spins Count (Dozens, Columns)</h3>
            <div className="selector-grid">
              {[...Array(18)].map((_, i) => (
                <button 
                  key={i + 3} 
                  className={`selector-grid-button ${thirdsStreakCount === i + 3 ? 'selected' : ''}`}
                  onClick={() => {
                    handleThirdsStreakCountChange(i + 3);
                    setShowThirdsSelector(false);
                  }}
                >
                  {i + 3}
                </button>
              ))}
            </div>
            <button 
              className="selector-close-button"
              onClick={() => setShowThirdsSelector(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}
      
      <div className="help-text">
        <p>
          Pattern detection will identify repeating sequences of spins. When a pattern 
          repeats, you'll receive a notification showing the pattern details. 
        </p>
        <p>
          A higher number of consecutive spins will detect longer, more specific patterns 
          but will require more history to find matches.
        </p>
      </div>
    </div>
  );
};


export default Settings;