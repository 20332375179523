import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

const SessionContext = createContext();

export const useSession = () => {
    return useContext(SessionContext);
};

export const SessionProvider = ({ children }) => {
    const [session, setSession] = useState(() => {
        const storedSession = localStorage.getItem('session');
        return storedSession ? JSON.parse(storedSession) : null;
    });

    const [selectedSpins, setSelectedSpins] = useState(() => {
        const storedSpins = localStorage.getItem('selectedSpins');
        return storedSpins ? JSON.parse(storedSpins) : [];
    });

    useEffect(() => {
        if (session) {
            localStorage.setItem('session', JSON.stringify(session));
            localStorage.setItem('selectedSpins', JSON.stringify(selectedSpins));
        } else {
            localStorage.removeItem('session');
            localStorage.removeItem('selectedSpins');
        }
    }, [session, selectedSpins]);

    const startSession = (sessionData) => {
        // Clear existing pattern history when starting a new session
        localStorage.removeItem('patternHistory');
        
        // Reset pattern detection settings to defaults
        localStorage.setItem('evenOddsStreakCount', '10');  // Default value
        localStorage.setItem('thirdsStreakCount', '6');    // Default value
        localStorage.setItem('enableEvenOddsPatterns', 'true');
        localStorage.setItem('enableThirdsPatterns', 'true');
        
        // Dispatch events to update UI
        window.dispatchEvent(new Event('patternHistoryUpdated'));
        window.dispatchEvent(new Event('storage'));
        
        // Set the new session
        setSession(sessionData);
        setSelectedSpins([]);
    };

    const clearSessionData = () => {
        setSession(null);
        setSelectedSpins([]);
    };

    const endSession = async (note) => {
        if (session) {
            try {
                // Get pattern history to save with the session
                const patternHistory = localStorage.getItem('patternHistory');
                
                // Save settings before clearing localStorage
                const savedSettings = {
                    analysisCriteria: localStorage.getItem('analysisCriteria'),
                    defaultScreen: localStorage.getItem('defaultScreen'),
                    showCountBadge: localStorage.getItem('showCountBadge'),
                    borderColor: localStorage.getItem('borderColor'),
                    evenOddsStreakCount: localStorage.getItem('evenOddsStreakCount'),
                    thirdsStreakCount: localStorage.getItem('thirdsStreakCount'),
                    enableEvenOddsPatterns: localStorage.getItem('enableEvenOddsPatterns'),
                    enableThirdsPatterns: localStorage.getItem('enableThirdsPatterns'),
                    neighborCount: localStorage.getItem('neighborCount')
                };
                
                // End the session and save pattern history
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/sessions/end`, {
                    sessionId: session._id,
                    post_session_notes: note,
                    patternHistory: patternHistory
                });
                
                // Clear localStorage but preserve settings
                localStorage.clear();
                
                // Restore saved settings
                for (const [key, value] of Object.entries(savedSettings)) {
                    if (value !== null) {
                        localStorage.setItem(key, value);
                    }
                }
                
                // Dispatch events to update UI components
                window.dispatchEvent(new Event('sessionEnded'));
                window.dispatchEvent(new Event('storage'));
                window.dispatchEvent(new Event('patternHistoryUpdated'));
                
                clearSessionData();
            } catch (error) {
                if (error.response && error.response.data.message === "Session not found") {
                    clearSessionData();
                } else {
                    console.error('Error ending session:', error);
                    throw error;
                }
            }
        }
    };

    const openPreviousSession = async (sessionId) => {
        try {
            // End current session if exists
            if (session) {
                await endSession();
            }

            // Open the selected session
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/sessions/${sessionId}/open`);
            const openedSession = response.data.session;
            
            // Fetch spins for the opened session
            const spinsResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/spins/${sessionId}/spins`);
            const sessionSpins = spinsResponse.data;
            
            // Restore pattern history if available
            if (openedSession.patternHistory) {
                localStorage.setItem('patternHistory', openedSession.patternHistory);
                
                // Trigger UI updates for pattern history
                window.dispatchEvent(new Event('patternHistoryUpdated'));
                window.dispatchEvent(new Event('storage'));
            }

            setSession(openedSession);
            setSelectedSpins(sessionSpins);

            return openedSession;
        } catch (error) {
            console.error('Error opening previous session:', error);
            throw error;
        }
    };

    return (
        <SessionContext.Provider value={{ 
            session, 
            startSession, 
            endSession, 
            openPreviousSession,
            selectedSpins, 
            setSelectedSpins 
        }}>
            {children}
        </SessionContext.Provider>
    );
};