// import React, { useEffect, useState, useMemo } from "react";
// import { useSession } from "../context/SessionContext";
// import { useSpin } from "../context/SpinContext";
// import styles from './Rep.module.css';
// import UpdateSpinModal from '../components/UpdateSpinModal';

// const Rep = () => {
//   const { session } = useSession();
//   const { state: { spins, spinsFetched }, fetchSpins, updateSpin, deleteSpin } = useSpin();
//   const [loading, setLoading] = useState(false);
//   const [targetUniqueSpins, setTargetUniqueSpins] = useState(14);
//   const [showModal, setShowModal] = useState(false);
//   const [currentSpin, setCurrentSpin] = useState(null);
//   const [showCountBadge, setShowCountBadge] = useState(false);

//   useEffect(() => {
//     const savedCriteria = JSON.parse(localStorage.getItem('analysisCriteria'));
//     if (savedCriteria && savedCriteria.repeatCount) {
//       setTargetUniqueSpins(savedCriteria.repeatCount);
//     }

//     const showBadge = localStorage.getItem('showCountBadge') === 'true';
//     setShowCountBadge(showBadge);
//   }, []);

//   useEffect(() => {
//     if (session?._id && !loading && !spinsFetched) {
//       setLoading(true);
//       fetchSpins(session._id)
//         .catch(error => {
//           console.error("Error fetching spins:", error);
//         })
//         .finally(() => setLoading(false));
//     }
//   }, [session?._id, loading, spinsFetched, fetchSpins]);

//   const processedSpins = useMemo(() => {
//     if (!spins.length) return [];
    
//     let result = [];
//     let runningTotal = 0;
    
//     // Initialize tracked numbers array
//     let trackedNumbers = [];
    
//     // Process each spin
//     for (let i = 0; i < spins.length; i++) {
//       const currentSpin = spins[i];
//       const spinValue = currentSpin.spin_value;
//       let calculatedOutcome = '---';
      
//       // Check if we have enough unique numbers to determine a win/loss
//       if (trackedNumbers.length >= targetUniqueSpins) {
//         // We have enough tracked numbers to determine win/loss
//         if (trackedNumbers.includes(spinValue)) {
//           calculatedOutcome = 'W';
          
//           // On a win, rebuild the tracked numbers starting with this number
//           // and working backwards to find unique values
//           const newTrackedNumbers = [spinValue];
//           const previousSpins = spins.slice(0, i).reverse(); // All previous spins in reverse order
          
//           // Loop through previous spins until we have targetUniqueSpins unique numbers
//           for (let j = 0; j < previousSpins.length && newTrackedNumbers.length < targetUniqueSpins; j++) {
//             const prevValue = previousSpins[j].spin_value;
//             if (!newTrackedNumbers.includes(prevValue)) {
//               newTrackedNumbers.push(prevValue);
//             }
//           }
          
//           // Update tracked numbers
//           trackedNumbers = newTrackedNumbers;
//         } else {
//           // It's a loss - current value is not in tracked numbers
//           calculatedOutcome = 'L';
//           // No change to tracked numbers on a loss
//         }
//       } else {
//         // Still building the initial set of tracked numbers
//         if (!trackedNumbers.includes(spinValue)) {
//           trackedNumbers.push(spinValue);
//         }
//       }
      
//       // Calculate display bet and running total from user inputs
//       const betAmount = currentSpin.repBet || 0;
//       let displayBet = 0;
      
//       if (betAmount !== 0 && currentSpin.repOutcome) {
//         displayBet = currentSpin.repOutcome === 'win' ? Math.abs(betAmount) : -Math.abs(betAmount);
//         runningTotal += displayBet;
//       }

//       result.push({
//         ...currentSpin,
//         calculatedOutcome,
//         displayBet,
//         running_total: runningTotal,
//         trackedNumbers: [...trackedNumbers] // Save current state of tracked numbers
//       });
//     }
  
//     // Reverse the result array to show most recent spins at the top
//     return [...result].reverse();
//   }, [spins, targetUniqueSpins]);

//   const uniqueSpins = useMemo(() => {
//     if (processedSpins.length === 0) return [];
//     // Since processedSpins is now reversed, we need to get the first item for the most recent spin
//     return processedSpins[0].trackedNumbers;
//   }, [processedSpins]);

//   const handleEditSpin = (spin) => {
//     setCurrentSpin({
//       ...spin,
//       repOutcome: spin.repOutcome 
//     });
//     setShowModal(true);
//   };

//   const handleUpdateSpin = (updatedSpin) => {
//     updateSpin(updatedSpin);
//     setShowModal(false);
//   };

//   const handleDeleteSpin = (deletedSpin) => {
//     deleteSpin(deletedSpin);
//     setShowModal(false);
//   };

//   const getColor = useMemo(() => {
//     return (spinValue) => {
//       const redNumbers = ['1', '3', '5', '7', '9', '12', '14', '16', '18', '19', '21', '23', '25', '27', '30', '32', '34', '36'];
//       const blackNumbers = ['2', '4', '6', '8', '10', '11', '13', '15', '17', '20', '22', '24', '26', '28', '29', '31', '33', '35'];
//       if (spinValue === '0' || spinValue === '00') return 'green';
//       if (redNumbers.includes(spinValue)) return 'red';
//       if (blackNumbers.includes(spinValue)) return 'black';
//       return '';
//     };
//   }, []);

//   const renderRouletteGrid = () => {
//     const numbers = Array.from({ length: 36 }, (_, i) => i + 1);
//     const rows = [];
    
//     for (let i = 0; i < numbers.length; i += 3) {
//       const rowNumbers = numbers.slice(i, i + 3);
//       rows.push(
//         <React.Fragment key={i}>
//           <div className={styles.gridRow}>
//             {rowNumbers.map(number => {
//               const stringNumber = number.toString();
//               const isUnique = uniqueSpins.includes(stringNumber);
//               const occurrences = spins.filter(spin => spin.spin_value === stringNumber).length;
              
//               return (
//                 <div 
//                   key={number}
//                   className={`${styles.rouletteNumber} ${styles[getColor(stringNumber)]} ${
//                     uniqueSpins.length === targetUniqueSpins && isUnique ? styles.highlighted : ''
//                   }`}
//                 >
//                   {number}
//                   {showCountBadge && occurrences > 0 && (
//                     <span className={styles.count}>{occurrences}</span>
//                   )}
//                 </div>
//               );
//             })}
//           </div>
//           {rowNumbers[rowNumbers.length - 1] === 12 || rowNumbers[rowNumbers.length - 1] === 24 ? 
//             <div className={styles.horizontalDivider} /> : null}
//         </React.Fragment>
//       );
//     }
    
//     return rows;
//   };

//   const hasTargetUniques = uniqueSpins.length === targetUniqueSpins;

//   if (loading) return <div>Loading...</div>;

//   return (
//     <div className={styles.historyTab}>
//       <div className={styles.historyContent}>
//         <div className={styles.spinHistoryGrid} style={{ paddingBottom: '75px' }}>
//           <div className={styles.spinHistoryHeader}>
//             <div className={styles.headerNo}>No.</div>
//             <div className={styles.headerSpin}>Spin</div>
//             <div className={styles.headerWl}>W/L</div>
//             <div className={styles.headerBet}>Bet</div>
//             <div className={styles.headerRt}>RT</div>
//           </div>
//           {processedSpins.map((spin, index) => {
//             // Calculate the original spin number
//             const originalSpinNumber = spins.length - index;
            
//             return (
//               <div key={spin._id} className={styles.spinRecord} onClick={() => handleEditSpin(spin)}>
//                 <div className={styles.spinNumber}>{originalSpinNumber}</div>
//                 <div className={`${styles.spinValue} ${styles[getColor(spin.spin_value)]}`}>
//                   {spin.spin_value}
//                 </div>
//                 <div className={`${styles.outcome} ${
//                   spin.calculatedOutcome === 'W' ? styles.win :
//                   spin.calculatedOutcome === 'L' ? styles.loss :
//                   ''
//                 }`}>
//                   {spin.calculatedOutcome}
//                 </div>
//                 <div className={styles.betAmount}>
//                   {!spin.repBet || !spin.repOutcome || spin.repOutcome === '---'
//                     ? '---'
//                     : spin.displayBet.toString()
//                   }
//                 </div>
//                 <div className={styles.runningTotal}>{spin.running_total}</div>
//               </div>
//             );
//           })}
//         </div>
//       </div>
      
//       <div className={styles.rouletteContainer}>
//         <div className={`${styles.rouletteHeader} ${hasTargetUniques ? styles.headerHighlight : ''}`}>
//           Unique #s: {uniqueSpins.length}/{targetUniqueSpins}
//         </div>
//         <div className={styles.rouletteTable}>
//           <div className={styles.zerosRow}>
//             <div className={`${styles.rouletteNumber} ${styles.green} ${
//               uniqueSpins.length === targetUniqueSpins && 
//               uniqueSpins.includes('0') ? 
//               styles.highlighted : ''
//             }`}>
//               0
//               {showCountBadge && spins.filter(spin => spin.spin_value === '0').length > 0 && (
//                 <span className={styles.count}>
//                   {spins.filter(spin => spin.spin_value === '0').length}
//                 </span>
//               )}
//             </div>
//             <div className={`${styles.rouletteNumber} ${styles.green} ${
//               uniqueSpins.length === targetUniqueSpins && 
//               uniqueSpins.includes('00') ? 
//               styles.highlighted : ''
//             }`}>
//               00
//               {showCountBadge && spins.filter(spin => spin.spin_value === '00').length > 0 && (
//                 <span className={styles.count}>
//                   {spins.filter(spin => spin.spin_value === '00').length}
//                 </span>
//               )}
//             </div>
//           </div>
//           <div className={styles.numbersGrid}>
//             {renderRouletteGrid()}
//           </div>
//         </div>
//         <div className={styles.rouletteNumbers}>
//           {uniqueSpins.slice().reverse().map((spin, index) => (
//             <div 
//               key={index} 
//               className={`${styles.orderNumber} ${styles[getColor(spin)]}`}
//             >
//               {spin}
//             </div>
//           ))}
//         </div>
//       </div>

//       {currentSpin && (
//         <UpdateSpinModal
//           show={showModal}
//           handleClose={() => setShowModal(false)}
//           spin={currentSpin}
//           onUpdate={handleUpdateSpin}
//           onDelete={handleDeleteSpin}
//           betType="rep"
//         />
//       )}
//     </div>
//   );
// };

// export default Rep;

import React, { useEffect, useState, useMemo } from "react";
import { useSession } from "../context/SessionContext";
import { useSpin } from "../context/SpinContext";
import styles from './Rep.module.css';
import UpdateSpinModal from '../components/UpdateSpinModal';

const Rep = () => {
  const { session } = useSession();
  const { state: { spins, spinsFetched }, fetchSpins, updateSpin, deleteSpin } = useSpin();
  const [loading, setLoading] = useState(false);
  const [targetUniqueSpins, setTargetUniqueSpins] = useState(14);
  const [showModal, setShowModal] = useState(false);
  const [currentSpin, setCurrentSpin] = useState(null);
  const [showCountBadge, setShowCountBadge] = useState(false);

  useEffect(() => {
    const savedCriteria = JSON.parse(localStorage.getItem('analysisCriteria'));
    if (savedCriteria && savedCriteria.repeatCount) {
      setTargetUniqueSpins(savedCriteria.repeatCount);
    }

    const showBadge = localStorage.getItem('showCountBadge') === 'true';
    setShowCountBadge(showBadge);
  }, []);

  useEffect(() => {
    if (session?._id && !loading && !spinsFetched) {
      setLoading(true);
      fetchSpins(session._id)
        .catch(error => {
          console.error("Error fetching spins:", error);
        })
        .finally(() => setLoading(false));
    }
  }, [session?._id, loading, spinsFetched, fetchSpins]);

  const processedSpins = useMemo(() => {
    if (!spins.length) return [];
    
    // Create a copy of spins array to work with
    const result = [];
    let trackedNumbers = [];
    let runningTotal = 0;
    
    // Process each spin from oldest to newest
    for (let i = 0; i < spins.length; i++) {
      const currentSpin = spins[i];
      const spinValue = currentSpin.spin_value;
      let calculatedOutcome = '---';
      
      // Check if we have enough unique numbers to determine a win/loss
      if (trackedNumbers.length >= targetUniqueSpins) {
        // We have enough tracked numbers to determine win/loss
        if (trackedNumbers.includes(spinValue)) {
          calculatedOutcome = 'W';
          
          // On a win, rebuild the tracked numbers starting with this number
          // and working backwards to find unique values
          const newTrackedNumbers = [spinValue];
          const previousSpins = spins.slice(0, i).reverse(); // All previous spins in reverse order
          
          // Loop through previous spins until we have targetUniqueSpins unique numbers
          for (let j = 0; j < previousSpins.length && newTrackedNumbers.length < targetUniqueSpins; j++) {
            const prevValue = previousSpins[j].spin_value;
            if (!newTrackedNumbers.includes(prevValue)) {
              newTrackedNumbers.push(prevValue);
            }
          }
          
          // Update tracked numbers
          trackedNumbers = newTrackedNumbers;
        } else {
          // It's a loss - current value is not in tracked numbers
          calculatedOutcome = 'L';
          // No change to tracked numbers on a loss
        }
      } else {
        // Still building the initial set of tracked numbers
        if (!trackedNumbers.includes(spinValue)) {
          trackedNumbers.push(spinValue);
        }
      }
      
      // Calculate display bet based on user input (not calculated outcome)
      // For display purposes, we want positive for Win and negative for Loss
      let displayBet = 0;
      if (currentSpin.repBet && currentSpin.repBet !== 0 && currentSpin.repOutcome) {
        // Calculate proper display value based on outcome
        displayBet = currentSpin.repOutcome === 'win' ? Math.abs(currentSpin.repBet) : -Math.abs(currentSpin.repBet);
        // Update running total based on display bet
        runningTotal += displayBet;
      }

      result.push({
        ...currentSpin,
        calculatedOutcome,
        displayBet,
        running_total: runningTotal,
        trackedNumbers: [...trackedNumbers] // Save current state of tracked numbers
      });
    }
  
    // Reverse the result array to show most recent spins at the top
    return [...result].reverse();
  }, [spins, targetUniqueSpins]);

  const uniqueSpins = useMemo(() => {
    if (processedSpins.length === 0) return [];
    // Since processedSpins is now reversed, we need to get the first item for the most recent spin
    return processedSpins[0].trackedNumbers;
  }, [processedSpins]);

  const handleEditSpin = (spin) => {
    // Format the spin for the modal
    const spinForEdit = {
      ...spin,
      repOutcome: spin.repOutcome === 'win' ? 'W' : 
                  spin.repOutcome === 'loss' ? 'L' : '---'
    };
    setCurrentSpin(spinForEdit);
    setShowModal(true);
  };

  const handleUpdateSpin = (updatedSpin) => {
    updateSpin(updatedSpin);
    setShowModal(false);
  };

  const handleDeleteSpin = (deletedSpin) => {
    deleteSpin(deletedSpin);
    setShowModal(false);
  };

  const getColor = useMemo(() => {
    return (spinValue) => {
      const redNumbers = ['1', '3', '5', '7', '9', '12', '14', '16', '18', '19', '21', '23', '25', '27', '30', '32', '34', '36'];
      const blackNumbers = ['2', '4', '6', '8', '10', '11', '13', '15', '17', '20', '22', '24', '26', '28', '29', '31', '33', '35'];
      if (spinValue === '0' || spinValue === '00') return 'green';
      if (redNumbers.includes(spinValue)) return 'red';
      if (blackNumbers.includes(spinValue)) return 'black';
      return '';
    };
  }, []);

  const renderRouletteGrid = () => {
    const numbers = Array.from({ length: 36 }, (_, i) => i + 1);
    const rows = [];
    
    for (let i = 0; i < numbers.length; i += 3) {
      const rowNumbers = numbers.slice(i, i + 3);
      rows.push(
        <React.Fragment key={i}>
          <div className={styles.gridRow}>
            {rowNumbers.map(number => {
              const stringNumber = number.toString();
              const isUnique = uniqueSpins.includes(stringNumber);
              const occurrences = spins.filter(spin => spin.spin_value === stringNumber).length;
              
              return (
                <div 
                  key={number}
                  className={`${styles.rouletteNumber} ${styles[getColor(stringNumber)]} ${
                    uniqueSpins.length === targetUniqueSpins && isUnique ? styles.highlighted : ''
                  }`}
                >
                  {number}
                  {showCountBadge && occurrences > 0 && (
                    <span className={styles.count}>{occurrences}</span>
                  )}
                </div>
              );
            })}
          </div>
          {rowNumbers[rowNumbers.length - 1] === 12 || rowNumbers[rowNumbers.length - 1] === 24 ? 
            <div className={styles.horizontalDivider} /> : null}
        </React.Fragment>
      );
    }
    
    return rows;
  };

  const hasTargetUniques = uniqueSpins.length === targetUniqueSpins;

  if (loading) return <div>Loading...</div>;

  return (
    <div className={styles.historyTab}>
      <div className={styles.historyContent}>
        <div className={styles.spinHistoryGrid} style={{ paddingBottom: '75px' }}>
          <div className={styles.spinHistoryHeader}>
            <div className={styles.headerNo}>No.</div>
            <div className={styles.headerSpin}>Spin</div>
            <div className={styles.headerWl}>W/L</div>
            <div className={styles.headerBet}>Bet</div>
            <div className={styles.headerRt}>RT</div>
          </div>
          {processedSpins.map((spin, index) => {
            // Calculate the original spin number
            const originalSpinNumber = spins.length - index;
            
            return (
              <div key={spin._id} className={styles.spinRecord} onClick={() => handleEditSpin(spin)}>
                <div className={styles.spinNumber}>{originalSpinNumber}</div>
                <div className={`${styles.spinValue} ${styles[getColor(spin.spin_value)]}`}>
                  {spin.spin_value}
                </div>
                <div className={`${styles.outcome} ${
                  spin.calculatedOutcome === 'W' ? styles.win :
                  spin.calculatedOutcome === 'L' ? styles.loss :
                  ''
                }`}>
                  {spin.calculatedOutcome}
                </div>
                <div className={styles.betAmount}>
                  {!spin.repBet || !spin.repOutcome || spin.repOutcome === '---'
                    ? '---'
                    : spin.displayBet
                  }
                </div>
                <div className={styles.runningTotal}>{spin.running_total}</div>
              </div>
            );
          })}
        </div>
      </div>
      
      <div className={styles.rouletteContainer}>
        <div className={`${styles.rouletteHeader} ${hasTargetUniques ? styles.headerHighlight : ''}`}>
          Unique #s: {uniqueSpins.length}/{targetUniqueSpins}
        </div>
        <div className={styles.rouletteTable}>
          <div className={styles.zerosRow}>
            <div className={`${styles.rouletteNumber} ${styles.green} ${
              uniqueSpins.length === targetUniqueSpins && 
              uniqueSpins.includes('0') ? 
              styles.highlighted : ''
            }`}>
              0
              {showCountBadge && spins.filter(spin => spin.spin_value === '0').length > 0 && (
                <span className={styles.count}>
                  {spins.filter(spin => spin.spin_value === '0').length}
                </span>
              )}
            </div>
            <div className={`${styles.rouletteNumber} ${styles.green} ${
              uniqueSpins.length === targetUniqueSpins && 
              uniqueSpins.includes('00') ? 
              styles.highlighted : ''
            }`}>
              00
              {showCountBadge && spins.filter(spin => spin.spin_value === '00').length > 0 && (
                <span className={styles.count}>
                  {spins.filter(spin => spin.spin_value === '00').length}
                </span>
              )}
            </div>
          </div>
          <div className={styles.numbersGrid}>
            {renderRouletteGrid()}
          </div>
        </div>
        <div className={styles.rouletteNumbers}>
          {uniqueSpins.slice().reverse().map((spin, index) => (
            <div 
              key={index} 
              className={`${styles.orderNumber} ${styles[getColor(spin)]}`}
            >
              {spin}
            </div>
          ))}
        </div>
      </div>

      {currentSpin && (
        <UpdateSpinModal
          show={showModal}
          handleClose={() => setShowModal(false)}
          spin={currentSpin}
          onUpdate={handleUpdateSpin}
          onDelete={handleDeleteSpin}
          betType="rep"
        />
      )}
    </div>
  );
};

export default Rep;