// Convert spin values to pattern sequences
const getRedBlackPattern = (spin) => {
  if (spin === '0' || spin === '00') return 'G';
  const redNumbers = ['1', '3', '5', '7', '9', '12', '14', '16', '18', '19', '21', '23', '25', '27', '30', '32', '34', '36'];
  return redNumbers.includes(spin) ? 'R' : 'B';
};

const getOddEvenPattern = (spin) => {
  if (spin === '0' || spin === '00') return 'G';
  return parseInt(spin) % 2 === 0 ? 'E' : 'O';
};

const getHighLowPattern = (spin) => {
  if (spin === '0' || spin === '00') return 'G';
  return parseInt(spin) >= 19 ? 'H' : 'L';
};

const getDozenPattern = (spin) => {
  if (spin === '0' || spin === '00') return 'G';
  const spinInt = parseInt(spin);
  if (spinInt >= 1 && spinInt <= 12) return '1';
  if (spinInt >= 13 && spinInt <= 24) return '2';
  return '3';
};

const getColumnPattern = (spin) => {
  if (spin === '0' || spin === '00') return 'G';
  const spinInt = parseInt(spin);
  if (spinInt % 3 === 1) return '1';
  if (spinInt % 3 === 2) return '2';
  return '3';
};

const convertSpinsToPatterns = (spins) => {
  const spinValues = spins.map(spin => spin.spin_value);
  return {
    redBlack: spinValues.map(getRedBlackPattern).join(''),
    oddEven: spinValues.map(getOddEvenPattern).join(''),
    highLow: spinValues.map(getHighLowPattern).join(''),
    dozen: spinValues.map(getDozenPattern).join(''),
    column: spinValues.map(getColumnPattern).join('')
  };
};

// Find all occurrences of a pattern in a string
const findAllOccurrences = (str, pattern) => {
  const positions = [];
  let pos = str.indexOf(pattern);
  while (pos !== -1) {
    positions.push(pos);
    // Important: move search position by 1 to find overlapping patterns
    pos = str.indexOf(pattern, pos + 1);
  }
  return positions;
};

// Find repeating patterns - UPDATED to use most recent occurrence
const findRepeatingPatterns = (pattern, streakCount) => {
  // Ensure we have enough spins to check (pattern length should be at least streakCount)
  if (pattern.length < streakCount) return null;
  
  // Get the last streak of characters - must match exactly the requested streak count
  const lastStreak = pattern.slice(-streakCount);
  
  // Only look for the pattern in the previous data (excluding the current streak)
  const previousPattern = pattern.slice(0, -streakCount);
  
  // Check if the previous pattern contains this exact sequence
  const fullPatternMatch = previousPattern.includes(lastStreak);
  
  if (!fullPatternMatch) return null;

  // Find all occurrences of this pattern
  const allOccurrences = findAllOccurrences(pattern, lastStreak);
  
  // Need at least 2 occurrences (previous + current)
  if (allOccurrences.length <= 1) return null;
  
  // Remove the last occurrence (current one) and get the most recent previous occurrence
  const previousOccurrences = allOccurrences.slice(0, -1);
  const mostRecentPreviousOccurrence = previousOccurrences[previousOccurrences.length - 1];
  
  const previousEndPosition = mostRecentPreviousOccurrence + streakCount - 1;
  
  return {
    pattern: lastStreak,
    previousOccurrence: previousEndPosition,
    totalOccurrences: allOccurrences.length,
    nextSixAfter: pattern.slice(previousEndPosition + 1, previousEndPosition + 9)
  };
};

// Main pattern detection function
// The updated detectPatterns function for patternUtils.js
// The updated detectPatterns function for patternUtils.js
const detectPatterns = (spins, settings) => {
  if (!spins || spins.length < 3) return null;
  
  const patterns = convertSpinsToPatterns(spins);
  const results = {};
  
  if (settings.enableEvenOdds) {
    const types = ['redBlack', 'oddEven', 'highLow'];
    for (const type of types) {
      // Make sure to use the exact streak count from settings
      const match = findRepeatingPatterns(patterns[type], settings.evenOddsStreakCount);
      if (match) {
        // Verify that the pattern length matches the streak count
        if (match.pattern.length === settings.evenOddsStreakCount) {
          results[type] = {
            ...match,
            patternType: type
          };
        }
      }
    }
  }
  
  if (settings.enableThirds) {
    const types = ['dozen', 'column'];
    for (const type of types) {
      // Make sure to use the exact streak count from settings
      const match = findRepeatingPatterns(patterns[type], settings.thirdsStreakCount);
      if (match) {
        // Verify that the pattern length matches the streak count
        if (match.pattern.length === settings.thirdsStreakCount) {
          results[type] = {
            ...match,
            patternType: type
          };
        }
      }
    }
  }
  
  return Object.keys(results).length > 0 ? results : null;
};


export const patternUtils = {
  detectPatterns,
  convertSpinsToPatterns,
  getRedBlackPattern,
  getOddEvenPattern,
  getHighLowPattern,
  getDozenPattern,
  getColumnPattern
};