// import React, { useEffect, useState, useMemo } from "react";
// import { useNavigate } from "react-router-dom";
// import { useSession } from "../context/SessionContext";
// import { useSpin } from "../context/SpinContext";
// import styles from './Neighbors.module.css';
// import UpdateSpinModal from '../components/UpdateSpinModal';

// const RACETRACK_ORDER = [
//   '0', '28', '9', '26', '30', '11', '7', '20', '32', '17', '5', '22', '34', 
//   '15', '3', '24', '36', '13', '1', '00', '27', '10', '25', '29', '12', '8', 
//   '19', '31', '18', '6', '21', '33', '16', '4', '23', '35', '14', '2'
// ];

// const Neighbors = () => {
//   const { session } = useSession();
//   const { state: { spins, spinsFetched }, fetchSpins, updateSpin, deleteSpin } = useSpin();
//   const [loading, setLoading] = useState(false);
//   const [neighborCount, setNeighborCount] = useState(6);
//   const [showModal, setShowModal] = useState(false);
//   const [currentSpin, setCurrentSpin] = useState(null);
//   const navigate = useNavigate(); 

//   useEffect(() => {
//     const savedCriteria = JSON.parse(localStorage.getItem('analysisCriteria'));
//     if (savedCriteria?.neighborsCount) {
//       setNeighborCount(parseInt(savedCriteria.neighborsCount));
//     }
//   }, []);

//   useEffect(() => {
//     if (session?._id && !loading && !spinsFetched) {
//       setLoading(true);
//       fetchSpins(session._id)
//         .catch(error => {
//           console.error("Error fetching spins:", error);
//         })
//         .finally(() => setLoading(false));
//     }
//   }, [session?._id, loading, spinsFetched, fetchSpins]);

//   const handleNeighborCountClick = () => {
//     navigate('/settings'); // Navigate to the settings page
//   };

//   const getNeighborNumbers = (lastNumber, count) => {
//     if (!lastNumber || !RACETRACK_ORDER.includes(lastNumber)) return [];
    
//     const currentIndex = RACETRACK_ORDER.indexOf(lastNumber);
//     const neighbors = [];
//     const totalNumbers = RACETRACK_ORDER.length;
    
//     neighbors.push(lastNumber);
    
//     for (let i = 1; i <= count; i++) {
//       let beforeIndex = currentIndex - i;
//       if (beforeIndex < 0) beforeIndex += totalNumbers;
//       neighbors.unshift(RACETRACK_ORDER[beforeIndex]);
      
//       let afterIndex = currentIndex + i;
//       if (afterIndex >= totalNumbers) afterIndex -= totalNumbers;
//       neighbors.push(RACETRACK_ORDER[afterIndex]);
//     }

//     return neighbors;
//   };

//   const isWithinNeighborRange = (currentNumber, previousNumber) => {
//     const currentIndex = RACETRACK_ORDER.indexOf(currentNumber);
//     const previousIndex = RACETRACK_ORDER.indexOf(previousNumber);
    
//     if (currentIndex === -1 || previousIndex === -1) return false;
    
//     const distance = Math.min(
//       Math.abs(currentIndex - previousIndex),
//       Math.abs(currentIndex - previousIndex + RACETRACK_ORDER.length),
//       Math.abs(currentIndex - previousIndex - RACETRACK_ORDER.length)
//     );
    
//     return distance <= neighborCount;
//   };

//   const processedSpins = useMemo(() => {
//     if (!spins.length) return [];
    
//     let result = [];
//     let runningTotal = 0;
    
//     spins.forEach((spin, index) => {
//       const spinValue = spin.spin_value;
//       let calculatedOutcome = '---';
      
//       if (index > 0) {
//         const previousSpin = spins[index - 1];
//         calculatedOutcome = isWithinNeighborRange(spinValue, previousSpin.spin_value) ? 'W' : 'L';
        
//         // Calculate display bet amount and running total
//         const betAmount = spin.neighborsBet || 0;
//         let displayBet = 0;
        
//         if (betAmount !== 0 && spin.neighborsOutcome) {
//           // Display positive for Win, negative for Loss based on user's entered outcome
//           displayBet = spin.neighborsOutcome === 'W' ? Math.abs(betAmount) : -Math.abs(betAmount);
//           // Add displayBet to running total
//           runningTotal += displayBet;
//         }

//         result.push({
//           ...spin,
//           calculatedOutcome,
//           displayBet,
//           running_total: runningTotal
//         });
//       } else {
//         // First spin
//         result.push({
//           ...spin,
//           calculatedOutcome: '---',
//           displayBet: 0,
//           running_total: 0
//         });
//       }
//     });

//     // Return a reversed copy of the array to show most recent spins at the top
//     return [...result].reverse();
//   }, [spins, neighborCount]);

//   const lastSpinValue = useMemo(() => {
//     return spins.length > 0 ? spins[spins.length - 1].spin_value : null;
//   }, [spins]);

//   const numbersToBet = useMemo(() => {
//     if (!lastSpinValue) return [];
//     return getNeighborNumbers(lastSpinValue, neighborCount);
//   }, [lastSpinValue, neighborCount]);

//   const handleEditSpin = (spin) => {
//     // Format exactly like Rep does 
//     const spinForEdit = {
//       ...spin,
//       // For no bet, use just 'NB', otherwise use 'W' or 'L'
//       neighborsOutcome: !spin.neighborsBet ? 'NB' : 
//                        spin.neighborsOutcome === 'win' ? 'W' :
//                        spin.neighborsOutcome === 'loss' ? 'L' :
//                        spin.neighborsOutcome || 'NB',
//       neighborsBet: parseInt(spin.neighborsBet) || 0
//     };
//     setCurrentSpin(spinForEdit);
//     setShowModal(true);
//   };

//   const handleUpdateSpin = (updatedSpin) => {
//     updateSpin(updatedSpin);
//     setShowModal(false);
//   };

//   const handleDeleteSpin = (deletedSpin) => {
//     deleteSpin(deletedSpin);
//     setShowModal(false);
//   };

//   const getColor = useMemo(() => {
//     return (spinValue) => {
//       const redNumbers = ['1', '3', '5', '7', '9', '12', '14', '16', '18', '19', '21', '23', '25', '27', '30', '32', '34', '36'];
//       const blackNumbers = ['2', '4', '6', '8', '10', '11', '13', '15', '17', '20', '22', '24', '26', '28', '29', '31', '33', '35'];
//       if (spinValue === '0' || spinValue === '00') return 'green';
//       if (redNumbers.includes(spinValue)) return 'red';
//       if (blackNumbers.includes(spinValue)) return 'black';
//       return '';
//     };
//   }, []);

//   const renderRouletteGrid = () => {
//     const numbers = Array.from({ length: 36 }, (_, i) => i + 1);
//     const rows = [];
    
//     for (let i = 0; i < numbers.length; i += 3) {
//       const rowNumbers = numbers.slice(i, i + 3);
//       rows.push(
//         <React.Fragment key={i}>
//           <div className={styles.gridRow}>
//             {rowNumbers.map(number => {
//               const isNeighbor = numbersToBet.includes(number.toString());
              
//               return (
//                 <div 
//                   key={number}
//                   className={`${styles.rouletteNumber} ${styles[getColor(number.toString())]} ${
//                     isNeighbor ? styles.highlighted : ''
//                   }`}
//                 >
//                   {number}
//                 </div>
//               );
//             })}
//           </div>
//           {rowNumbers[rowNumbers.length - 1] === 12 || rowNumbers[rowNumbers.length - 1] === 24 ? (
//             <div className={styles.horizontalDivider} />
//           ) : null}
//         </React.Fragment>
//       );
//     }
    
//     return rows;
//   };

//   if (loading) return <div>Loading...</div>;

//   return (
//     <div className={styles.historyTab}>
//       <div className={styles.historyContent}>
//         <div className={styles.spinHistoryGrid} style={{ paddingBottom: '75px' }}>
//           <div className={styles.spinHistoryHeader}>
//             <div className={styles.headerNo}>No.</div>
//             <div className={styles.headerSpin}>Spin</div>
//             <div className={styles.headerWl}>W/L</div>
//             <div className={styles.headerBet}>Bet</div>
//             <div className={styles.headerRt}>RT</div>
//           </div>
//           {processedSpins.map((spin, index) => {
//             // Calculate the original spin number
//             const originalSpinNumber = spins.length - index;
            
//             return (
//               <div key={spin._id} className={styles.spinRecord} onClick={() => handleEditSpin(spin)}>
//                 <div className={styles.spinNumber}>{originalSpinNumber}</div>
//                 <div className={`${styles.spinValue} ${styles[getColor(spin.spin_value)]}`}>
//                   {spin.spin_value}
//                 </div>
//                 <div className={`${styles.outcome} ${
//                   spin.calculatedOutcome === 'W' ? styles.win :
//                   spin.calculatedOutcome === 'L' ? styles.loss :
//                   ''
//                 }`}>
//                   {spin.calculatedOutcome}
//                 </div>
//                 <div className={styles.betAmount}>
//                   {!spin.neighborsBet || !spin.neighborsOutcome || spin.neighborsOutcome === '---'
//                     ? '---'
//                     : spin.displayBet.toString()}
//                 </div>
//                 <div className={styles.runningTotal}>{spin.running_total}</div>
//               </div>
//             );
//           })}
//         </div>
//       </div>

//       <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
//         <div
//           className={styles.neighborCountContainer}
//           onClick={handleNeighborCountClick} // Add onClick event
//           style={{ cursor: 'pointer' }} // Add a pointer cursor to indicate clickability
//         >
//           <div className="flex items-center">
//             <span className={styles.neighborCountLabel}>Selected:</span>
//             <span className={styles.neighborCountValue}>{neighborCount}</span>
//           </div>
//         </div>

//         <div className={styles.rouletteContainer}>
//           <div className={styles.rouletteTable}>
//             <div className={styles.zerosRow}>
//               <div className={`${styles.rouletteNumber} ${styles.green} ${
//                 numbersToBet.includes('0') ? styles.highlighted : ''
//               }`}>
//                 0
//               </div>
//               <div className={`${styles.rouletteNumber} ${styles.green} ${
//                 numbersToBet.includes('00') ? styles.highlighted : ''
//               }`}>
//                 00
//               </div>
//             </div>
//             <div className={styles.numbersGrid}>
//               {renderRouletteGrid()}
//             </div>
//           </div>
//           <div className={styles.rouletteNumbers}>
//             {numbersToBet.map((number, index) => (
//               <div 
//                 key={index} 
//                 className={`${styles.orderNumber} ${styles[getColor(number)]}`}
//               >
//                 {number}
//               </div>
//             ))}
//           </div>
//         </div>

//         {currentSpin && (
//           <UpdateSpinModal
//             show={showModal}
//             handleClose={() => setShowModal(false)}
//             spin={currentSpin}
//             onUpdate={handleUpdateSpin}
//             onDelete={handleDeleteSpin}
//             betType="neighbors"
//           />
//         )}
//       </div>
//     </div>
//   );
// };

// export default Neighbors;


import React, { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useSession } from "../context/SessionContext";
import { useSpin } from "../context/SpinContext";
import styles from './Neighbors.module.css';
import UpdateSpinModal from '../components/UpdateSpinModal';

const RACETRACK_ORDER = [
  '0', '28', '9', '26', '30', '11', '7', '20', '32', '17', '5', '22', '34', 
  '15', '3', '24', '36', '13', '1', '00', '27', '10', '25', '29', '12', '8', 
  '19', '31', '18', '6', '21', '33', '16', '4', '23', '35', '14', '2'
];

const Neighbors = () => {
  const { session } = useSession();
  const { state: { spins, spinsFetched }, fetchSpins, updateSpin, deleteSpin } = useSpin();
  const [loading, setLoading] = useState(false);
  const [neighborCount, setNeighborCount] = useState(6);
  const [showModal, setShowModal] = useState(false);
  const [currentSpin, setCurrentSpin] = useState(null);
  const navigate = useNavigate(); 

  useEffect(() => {
    const savedCriteria = JSON.parse(localStorage.getItem('analysisCriteria'));
    if (savedCriteria?.neighborsCount) {
      setNeighborCount(parseInt(savedCriteria.neighborsCount));
    }
  }, []);

  useEffect(() => {
    if (session?._id && !loading && !spinsFetched) {
      setLoading(true);
      fetchSpins(session._id)
        .catch(error => {
          console.error("Error fetching spins:", error);
        })
        .finally(() => setLoading(false));
    }
  }, [session?._id, loading, spinsFetched, fetchSpins]);

  const handleNeighborCountClick = () => {
    navigate('/settings'); // Navigate to the settings page
  };

  const getNeighborNumbers = (lastNumber, count) => {
    if (!lastNumber || !RACETRACK_ORDER.includes(lastNumber)) return [];
    
    const currentIndex = RACETRACK_ORDER.indexOf(lastNumber);
    const neighbors = [];
    const totalNumbers = RACETRACK_ORDER.length;
    
    neighbors.push(lastNumber);
    
    for (let i = 1; i <= count; i++) {
      let beforeIndex = currentIndex - i;
      if (beforeIndex < 0) beforeIndex += totalNumbers;
      neighbors.unshift(RACETRACK_ORDER[beforeIndex]);
      
      let afterIndex = currentIndex + i;
      if (afterIndex >= totalNumbers) afterIndex -= totalNumbers;
      neighbors.push(RACETRACK_ORDER[afterIndex]);
    }

    return neighbors;
  };

  const isWithinNeighborRange = (currentNumber, previousNumber) => {
    const currentIndex = RACETRACK_ORDER.indexOf(currentNumber);
    const previousIndex = RACETRACK_ORDER.indexOf(previousNumber);
    
    if (currentIndex === -1 || previousIndex === -1) return false;
    
    const distance = Math.min(
      Math.abs(currentIndex - previousIndex),
      Math.abs(currentIndex - previousIndex + RACETRACK_ORDER.length),
      Math.abs(currentIndex - previousIndex - RACETRACK_ORDER.length)
    );
    
    return distance <= neighborCount;
  };

  const processedSpins = useMemo(() => {
    if (!spins.length) return [];
    
    const result = spins.map((spin, index) => {
      const spinValue = spin.spin_value;
      let calculatedOutcome = '---';
      
      if (index > 0) {
        const previousSpin = spins[index - 1];
        calculatedOutcome = isWithinNeighborRange(spinValue, previousSpin.spin_value) ? 'W' : 'L';
      }
      
      // For display purposes, we should show positive for win and negative for loss
      // regardless of how the value is stored in the database
      let displayBet = 0;
      if (spin.neighborsBet && spin.neighborsBet !== 0 && spin.neighborsOutcome) {
        // Always use the absolute bet amount with appropriate sign based on outcome
        displayBet = spin.neighborsOutcome === 'win' ? Math.abs(spin.neighborsBet) : -Math.abs(spin.neighborsBet);
      }
      
      // Use the running total from the SpinContext which should be correct
      const runningTotal = spin.neighborsRunningTotal || 0;
      
      return {
        ...spin,
        calculatedOutcome,
        displayBet,
        running_total: runningTotal
      };
    });
    
    // Return a reversed copy to show most recent spins at the top
    return [...result].reverse();
  }, [spins, neighborCount]);

  const lastSpinValue = useMemo(() => {
    return spins.length > 0 ? spins[spins.length - 1].spin_value : null;
  }, [spins]);

  const numbersToBet = useMemo(() => {
    if (!lastSpinValue) return [];
    return getNeighborNumbers(lastSpinValue, neighborCount);
  }, [lastSpinValue, neighborCount]);

  const handleEditSpin = (spin) => {
    // Format the spin data for the edit modal
    const spinForEdit = {
      ...spin,
      // For display in the modal, use string format (W/L/NB)
      neighborsOutcome: !spin.neighborsBet ? '---' : 
                       spin.neighborsOutcome === 'win' ? 'W' :
                       spin.neighborsOutcome === 'loss' ? 'L' : '---'
    };
    setCurrentSpin(spinForEdit);
    setShowModal(true);
  };

  const handleUpdateSpin = (updatedSpin) => {
    // Let the updateSpin function in SpinContext handle the running total updates
    updateSpin(updatedSpin);
    setShowModal(false);
  };

  const handleDeleteSpin = (deletedSpin) => {
    deleteSpin(deletedSpin);
    setShowModal(false);
  };

  const getColor = useMemo(() => {
    return (spinValue) => {
      const redNumbers = ['1', '3', '5', '7', '9', '12', '14', '16', '18', '19', '21', '23', '25', '27', '30', '32', '34', '36'];
      const blackNumbers = ['2', '4', '6', '8', '10', '11', '13', '15', '17', '20', '22', '24', '26', '28', '29', '31', '33', '35'];
      if (spinValue === '0' || spinValue === '00') return 'green';
      if (redNumbers.includes(spinValue)) return 'red';
      if (blackNumbers.includes(spinValue)) return 'black';
      return '';
    };
  }, []);

  const renderRouletteGrid = () => {
    const numbers = Array.from({ length: 36 }, (_, i) => i + 1);
    const rows = [];
    
    for (let i = 0; i < numbers.length; i += 3) {
      const rowNumbers = numbers.slice(i, i + 3);
      rows.push(
        <React.Fragment key={i}>
          <div className={styles.gridRow}>
            {rowNumbers.map(number => {
              const isNeighbor = numbersToBet.includes(number.toString());
              
              return (
                <div 
                  key={number}
                  className={`${styles.rouletteNumber} ${styles[getColor(number.toString())]} ${
                    isNeighbor ? styles.highlighted : ''
                  }`}
                >
                  {number}
                </div>
              );
            })}
          </div>
          {rowNumbers[rowNumbers.length - 1] === 12 || rowNumbers[rowNumbers.length - 1] === 24 ? (
            <div className={styles.horizontalDivider} />
          ) : null}
        </React.Fragment>
      );
    }
    
    return rows;
  };

  if (loading) return <div>Loading...</div>;

  return (
    <div className={styles.historyTab}>
      <div className={styles.historyContent}>
        <div className={styles.spinHistoryGrid} style={{ paddingBottom: '75px' }}>
          <div className={styles.spinHistoryHeader}>
            <div className={styles.headerNo}>No.</div>
            <div className={styles.headerSpin}>Spin</div>
            <div className={styles.headerWl}>W/L</div>
            <div className={styles.headerBet}>Bet</div>
            <div className={styles.headerRt}>RT</div>
          </div>
          {processedSpins.map((spin, index) => {
            // Calculate the original spin number
            const originalSpinNumber = spins.length - index;
            
            return (
              <div key={spin._id} className={styles.spinRecord} onClick={() => handleEditSpin(spin)}>
                <div className={styles.spinNumber}>{originalSpinNumber}</div>
                <div className={`${styles.spinValue} ${styles[getColor(spin.spin_value)]}`}>
                  {spin.spin_value}
                </div>
                <div className={`${styles.outcome} ${
                  spin.calculatedOutcome === 'W' ? styles.win :
                  spin.calculatedOutcome === 'L' ? styles.loss :
                  ''
                }`}>
                  {spin.calculatedOutcome}
                </div>
                <div className={styles.betAmount}>
                  {!spin.neighborsBet || !spin.neighborsOutcome || spin.neighborsOutcome === '---'
                    ? '---'
                    : spin.displayBet}
                </div>
                <div className={styles.runningTotal}>{spin.running_total}</div>
              </div>
            );
          })}
        </div>
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <div
          className={styles.neighborCountContainer}
          onClick={handleNeighborCountClick} // Add onClick event
          style={{ cursor: 'pointer' }} // Add a pointer cursor to indicate clickability
        >
          <div className="flex items-center">
            <span className={styles.neighborCountLabel}>Selected:</span>
            <span className={styles.neighborCountValue}>{neighborCount}</span>
          </div>
        </div>

        <div className={styles.rouletteContainer}>
          <div className={styles.rouletteTable}>
            <div className={styles.zerosRow}>
              <div className={`${styles.rouletteNumber} ${styles.green} ${
                numbersToBet.includes('0') ? styles.highlighted : ''
              }`}>
                0
              </div>
              <div className={`${styles.rouletteNumber} ${styles.green} ${
                numbersToBet.includes('00') ? styles.highlighted : ''
              }`}>
                00
              </div>
            </div>
            <div className={styles.numbersGrid}>
              {renderRouletteGrid()}
            </div>
          </div>
          <div className={styles.rouletteNumbers}>
            {numbersToBet.map((number, index) => (
              <div 
                key={index} 
                className={`${styles.orderNumber} ${styles[getColor(number)]}`}
              >
                {number}
              </div>
            ))}
          </div>
        </div>

        {currentSpin && (
          <UpdateSpinModal
            show={showModal}
            handleClose={() => setShowModal(false)}
            spin={currentSpin}
            onUpdate={handleUpdateSpin}
            onDelete={handleDeleteSpin}
            betType="neighbors"
          />
        )}
      </div>
    </div>
  );
};

export default Neighbors;