import React, { useEffect, useState, useMemo } from "react";
import { useSession } from "../context/SessionContext";
import { useSpin } from "../context/SpinContext";
import styles from './Dozens.module.css';
import sharedStyles from './Shared.css';
import UpdateSpinModal from '../components/UpdateSpinModal';

const calculateLookbackPercentages = (spins, criteria) => {
  // Use thirds criteria
  const lookback = criteria.thirdsLookback || criteria.spinsToLookBack || 5;
  const highlightPercentage = criteria.thirdsHighlight || criteria.startHighlightingAt || 0.49;
  const aboveOrBelow = criteria.thirdsAboveBelow || criteria.aboveOrBelow || 'Above';

  return spins.map((spin, index) => {
    if (index < lookback - 1) {
      return { ...spin, highlightD1: false, highlightD2: false, highlightD3: false };
    }

    const lookbackSpins = spins.slice(index - lookback + 1, index + 1);
    const d1Count = lookbackSpins.filter(spin => parseInt(spin.spin_value) >= 1 && parseInt(spin.spin_value) <= 12).length;
    const d2Count = lookbackSpins.filter(spin => parseInt(spin.spin_value) >= 13 && parseInt(spin.spin_value) <= 24).length;
    const d3Count = lookbackSpins.filter(spin => parseInt(spin.spin_value) >= 25 && parseInt(spin.spin_value) <= 36).length;
    const d1Percentage = Math.round((d1Count / lookback) * 100);
    const d2Percentage = Math.round((d2Count / lookback) * 100);
    const d3Percentage = Math.round((d3Count / lookback) * 100);

    let highlightD1 = false;
    let highlightD2 = false;
    let highlightD3 = false;
    const threshold = highlightPercentage * 100;

    if (aboveOrBelow === 'Above') {
      if (d1Percentage > threshold) {
        highlightD1 = true;
      }
      if (d2Percentage > threshold) {
        highlightD2 = true;
      }
      if (d3Percentage > threshold) {
        highlightD3 = true;
      }
    } else {
      if (d1Percentage < threshold) {
        highlightD1 = true;
      }
      if (d2Percentage < threshold) {
        highlightD2 = true;
      }
      if (d3Percentage < threshold) {
        highlightD3 = true;
      }
    }
    return { ...spin, d1Percentage, d2Percentage, d3Percentage, highlightD1, highlightD2, highlightD3 };
  });
};

const calculateRunningTotals = (spins) => {
  return spins.reduce((acc, spin, index) => {
    const previousTotal = index > 0 ? acc[index - 1].running_total : 0;
    const betAmount = spin.dozBet || 0;
    // Fixed to check for 'win' and 'loss' instead of 'W' and 'L'
    const outcomeMultiplier = spin.dozOutcome === 'win' ? 1 : spin.dozOutcome === 'loss' ? -1 : 0;
    const running_total = previousTotal + betAmount * outcomeMultiplier;
    return [...acc, { ...spin, running_total }];
  }, []);
};

const Dozens = () => {
  const { session } = useSession();
  const { state: { spins, spinsFetched }, fetchSpins, updateSpin, deleteSpin } = useSpin();
  const [loading, setLoading] = useState(false);
  const [currentSpin, setCurrentSpin] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const [criteria, setCriteria] = useState({
    thirdsLookback: 5,
    thirdsHighlight: 0.49,
    thirdsAboveBelow: 'Above',
    spinsToLookBack: 5,
    startHighlightingAt: 0.49,
    aboveOrBelow: 'Above'
  });

  // Fetch saved criteria from localStorage
  useEffect(() => {
    const savedCriteria = JSON.parse(localStorage.getItem('analysisCriteria') || '{}');
    setCriteria(savedCriteria);
  }, []);

  // Fetch spins when the session is available and spins are not yet fetched
  useEffect(() => {
    if (session?._id && !loading && !spinsFetched) {
      setLoading(true);
      console.log("Fetching spins...");
      fetchSpins(session._id)
        .catch(error => {
          console.error("Error fetching spins:", error);
        })
        .finally(() => setLoading(false));
    }
  }, [session?._id, loading, spinsFetched, fetchSpins]);

  // Stop further API calls if no spins are returned
  useEffect(() => {
    if (spinsFetched && spins.length === 0) {
      console.log('No spins returned, stopping further API calls.');
    }
  }, [spins, spinsFetched]);

  const handleEditSpin = (spin) => {
    console.log("Editing spin:", spin);
    setCurrentSpin(spin);
    setShowModal(true);
  };

  const handleUpdateSpin = (updatedSpin) => {
    updateSpin(updatedSpin);
    setShowModal(false);
  };

  const handleDeleteSpin = (deletedSpin) => {
    deleteSpin(deletedSpin);
    setShowModal(false);
  };

  const getColor = useMemo(() => {
    return (spinValue) => {
      const spinValueInt = parseInt(spinValue, 10);
      if (spinValue === '0' || spinValue === '00') {
        return styles.green; // Green for 0 and 00
      }
      if (spinValueInt >= 1 && spinValueInt <= 12) {
        return styles.d1; // Red for 1-12
      }
      if (spinValueInt >= 13 && spinValueInt <= 24) {
        return styles.d2; // Black for 13-24
      }
      if (spinValueInt >= 25 && spinValueInt <= 36) {
        return styles.d3; // Purple for 25-36
      }
      return '';
    };
  }, []);

  const highlightedSpins = useMemo(() => 
    calculateLookbackPercentages(spins, criteria),
    [spins, criteria]
  );

  const spinsWithRunningTotals = useMemo(() => {
    const calculatedSpins = calculateRunningTotals(highlightedSpins);
    // Return a reversed copy of the array to show most recent spins at the top
    return [...calculatedSpins].reverse();
  }, [highlightedSpins]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className={styles.historyTab}>
      <div className={styles.historyContent}>
        <div className={styles.spinHistoryGrid}>
          <div className={styles.spinHistoryHeader}>
            <div className={styles.headerNo}>No.</div>
            <div className={styles.headerSpin}>Spin</div>
            <div className={styles.headerD1}>D1</div>
            <div className={styles.headerD2}>D2</div>
            <div className={styles.headerD3}>D3</div>
            <div className={styles.headerWl}>W/L</div>
            <div className={styles.headerBet}>Bet</div>
            <div className={styles.headerRt}>RT</div>
          </div>
          {spinsWithRunningTotals.map((spin, index) => {
            // Calculate the original spin number by reverting the index
            const originalSpinNumber = spins.length - index;
            
            return (
              <div key={spin._id} className={`${styles.spinRecord} ${getColor(spin.spin_value)}`} onClick={() => handleEditSpin(spin)}> 
                <div className={styles.spinNumber}>{originalSpinNumber}</div>
                <div className={`${styles.spinValue} ${getColor(spin.spin_value)}`}>
                  {spin.spin_value}
                </div>
                <div className={`${styles.d1Column} ${spin.highlightD1 ? styles.highlight : ''}`}>
                  {spin.d1Percentage}%
                </div>
                <div className={`${styles.d2Column} ${spin.highlightD2 ? styles.highlight : ''}`}>
                  {spin.d2Percentage}%
                </div>
                <div className={`${styles.d3Column} ${spin.highlightD3 ? styles.highlight : ''}`}>
                  {spin.d3Percentage}%
                </div>
                <div className={`${styles.outcome} ${styles[spin.dozOutcome]}`}>
                  {spin.dozOutcome === 'win' ? 'W' : spin.dozOutcome === 'loss' ? 'L' : spin.dozOutcome === 'no bet' ? '---' : spin.dozOutcome}
                </div>
                <div className={styles.betAmount}>
                  {spin.dozOutcome === 'NB'
                    ? ''
                    : `${spin.dozOutcome === 'L' ? '-' : ''}${spin.dozBet}`}
                </div>
                <div className={styles.runningTotal}>{spin.running_total}</div>
              </div>
            );
          })}
        </div>
      </div>
      {currentSpin && (
        <UpdateSpinModal
          show={showModal}
          handleClose={() => setShowModal(false)}
          spin={currentSpin}
          onUpdate={handleUpdateSpin}
          onDelete={handleDeleteSpin}
          betType="doz" // Specify the bet type
        />
      )}
    </div>
  );
};

export default Dozens;