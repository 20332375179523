import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import RedBlack from '../components/RedBlack';
import OddEven from '../components/OddEven';
import HighLow from '../components/HighLow';
import Dozens from '../components/Dozens';
import Columns from '../components/Columns';
import Rep from '../components/Rep';
import Neighbors from '../components/Neighbors';
import styles from './Stats.module.css';
import { useSpin } from '../context/SpinContext';
import PatternHandler from '../components/PatternHandler';


const Stats = () => {
  const [activeTab, setActiveTab] = useState('RedBlack');
  const [criteria, setCriteria] = useState(null);
  const [patternKey, setPatternKey] = useState(0); // Add this state
  const navigate = useNavigate();
  const location = useLocation();
  const { fetchStats } = useSpin();

  useEffect(() => {
    // First check if there's a specific tab in the URL query
    const params = new URLSearchParams(location.search);
    const tab = params.get('tab');
    
    if (tab) {
      setActiveTab(tab);
    } else {
      const defaultScreen = localStorage.getItem('defaultScreen') || 'RedBlack';
      setActiveTab(defaultScreen);
      navigate(`/stats?tab=${defaultScreen}`, { replace: true });
    }
  }, [location.search, navigate]);

  useEffect(() => {
    const savedCriteria = JSON.parse(localStorage.getItem('analysisCriteria'));
    if (savedCriteria) {
      setCriteria(savedCriteria);
    } else {
      navigate('/settings', { state: { showCriteriaModal: true } });
    }
  }, [navigate]);

  useEffect(() => {
    // Check for pending patterns and update key if found
    const pendingPatterns = localStorage.getItem('pendingPatterns');
    console.log("Stats checking for pending patterns:", pendingPatterns);
    if (pendingPatterns) {
      setPatternKey(prev => prev + 1);
    }
  }, [location.search]);
  
  const handleTabClick = (tab) => {
    setActiveTab(tab);
    navigate(`/stats?tab=${tab}`, { replace: true });
  };

  return (
    <div className={styles.statsTab}>
      <div className={styles.statsContent}>
        {/* Add the PatternHandler component */}
        {/* <PatternHandler /> */}
        <PatternHandler key={patternKey} />
        {activeTab === 'RedBlack' && <RedBlack />}
        {activeTab === 'OddEven' && <OddEven />}
        {activeTab === 'HighLow' && <HighLow />}
        {activeTab === 'Dozens' && <Dozens />}
        {activeTab === 'Columns' && <Columns />}
        {activeTab === 'Rep' && <Rep />}
        {activeTab === 'Neb' && <Neighbors />}
      </div>
      <div className={styles.tabBar}>
        <div
          className={`${styles.tab} ${activeTab === 'RedBlack' ? styles.activeTab : ''}`}
          onClick={() => handleTabClick('RedBlack')}
        >
          R/B
        </div>
        <div
          className={`${styles.tab} ${activeTab === 'OddEven' ? styles.activeTab : ''}`}
          onClick={() => handleTabClick('OddEven')}
        >
          O/E
        </div>
        <div
          className={`${styles.tab} ${activeTab === 'HighLow' ? styles.activeTab : ''}`}
          onClick={() => handleTabClick('HighLow')}
        >
          H/L
        </div>
        <div
          className={`${styles.tab} ${activeTab === 'Dozens' ? styles.activeTab : ''}`}
          onClick={() => handleTabClick('Dozens')}
        >
          Doz
        </div>
        <div
          className={`${styles.tab} ${activeTab === 'Columns' ? styles.activeTab : ''}`}
          onClick={() => handleTabClick('Columns')}
        >
          Col
        </div>
        <div
          className={`${styles.tab} ${activeTab === 'Rep' ? styles.activeTab : ''}`}
          onClick={() => handleTabClick('Rep')}
        >
          Rep
        </div>
        <div
          className={`${styles.tab} ${activeTab === 'Neb' ? styles.activeTab : ''}`}
          onClick={() => handleTabClick('Neb')}
        >
          Neb
        </div>
      </div>
    </div>
  );
};

export default Stats;